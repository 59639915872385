/*import SignUp from './components/SignUp';
import AdminDashboard from './components/AdminDashboard';
import Home from './components/Home';
import AdminAddUser from './components/AdminAddUser';
import MultiPageFormModal from './components/MultiPageFormModal';*/
import ForgotPassword from './components/ForgotPassword';
import OtpVerificationForm from './components/OtpVerificationForm';
import VerifyAndResetPassword from './components/VerifyAndResetPassword';
//import Registration1 from './components/Registration1';
//import Registration2 from './components/Registration2';
import { Navbar } from './components/Navbar';
import Login from './components/Login';
import Registration3 from './components/Registration3';
//import Registration4 from './components/Registration4';
import { EmailProvider } from './components/EmailContext';
import { Routes, Route, Navigate } from 'react-router-dom';
//import { Navigate } from 'react-router-dom';
import { React } from 'react';
import FileUploadForm from './components/FileUploadForm';
import VerificationTable from './components/VerificationTable';
//import { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery/dist/jquery.min.js';
import LoginPatil from './components/LoginPatil';
import VendorStatus from './components/VendorStatus';
//import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VendorProvider } from './components/VendorContext';
//import { useEffect } from 'react';
//import LanguageSelection from './components/LanguageSelection';
//import { LanguageProvider } from './components/LanguageContext';
//import { Helmet } from 'react-helmet'; // Import Helmet


function App() {
  const navigate = useNavigate();
  //const [vendorNo, setVendorNo] = useState(null);
  const isLoggedIn = !!localStorage.getItem('token');
  console.log("isLoggedInValue is: ", isLoggedIn);
  const destination = "myhome";
  let vendorStatus= "";

  const handleSuccessfulLogin = (vendorNo, vendStatus) => {
    //setVendorNo(vendorNo);
    localStorage.setItem('token', 'your_token_value');
    console.log("vendor no currently is", vendorNo);
    vendorStatus=vendStatus;
    console.log("VendorStatus from APP.JS is", vendorStatus);
    if (vendStatus === "INITIATED" || vendStatus === "SAVED") {
      navigate(`/Registration3/${destination}`);
    }
    else if (vendStatus === "REQUESTED" || vendStatus === "CREATED" || vendStatus === "INAPP" || vendStatus === "APPROVED" || vendStatus==="REVERT" || vendStatus === "HOLD" || vendStatus==="REJECT" || vendStatus==="DELETE") {
      navigate(`/VendorStatus`);
    }
    //navigate(`/Registration3/${destination}`);
  };

  /*useEffect(() => {
    // Initialize the Google Translate API here
    function googleTranslateElementInit() {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en', // Default source language is English
          includedLanguages: 'en,es,fr', // Include the languages you want to support
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: false,
        },
        'google_translate_element'
      );
    }

    // Load the Google Translate API script if it hasn't been loaded already
    if (!window.google || !window.google.translate) {
      const script = document.createElement('script');
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      document.body.appendChild(script);
    } else {
      // API is already loaded, directly initialize it
      googleTranslateElementInit();
    }
  }, []);*/

  return (
    <div className="App">
      {/*<LanguageProvider>*/}
      {/*<Helmet>
           Add the Google Translate API script to the <head> 
          <script src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit" async />
        </Helmet>
        */}
      <Navigate
        onBack={() => {
          // Handle the custom back behavior here
          console.log('Custom back button behavior');
          // You can use 'navigate(-1)' to go back, or any custom logic
          navigate(-1);
        }}
      />
      <VendorProvider>
        <Navbar isAuthenticated={isLoggedIn} />
        <EmailProvider>
          <Routes>
            {/* <Route path="/signup" element={<SignUp />} />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path='/admin-adduser' element={<AdminAddUser/>}/>
            <Route path="/multipage-form" element={<MultiPageFormModal/>} />
            <Route exact path="/home" element={<Home />}/>*/}
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path="/verify-otp" element={<OtpVerificationForm />} />
            <Route path='/reset-password' element={<VerifyAndResetPassword />} />
            {/*<Route path='/Registration1' element={<Registration1 />} />
            <Route path='/Registration2' element={<Registration2 />} />
            <Route path='/Registration4' element={<Registration4 />} />*/}
            {/*<Route path='/Registration3/:vendorNo' element={<Registration3 />} />*/}
            <Route path="/login" element={<Login />} />

            <Route path="/" element={<Navigate to="/LoginPatil" />} />
            {/*<Route path="/LoginPatil"
             //element={<LoginPatil onSuccessfulLogin={handleSuccessfulLogin(vendorNo)}/>}
             element={
              isLoggedIn ? (
                <Navigate to={`/Registration3/${destination}`} />
              ) : (
                <LoginPatil onSuccessfulLogin={handleSuccessfulLogin} />
              )
            }
          />*/}
            {/*<Route path="/LoginPatil"
             //element={<LoginPatil onSuccessfulLogin={handleSuccessfulLogin(vendorNo)}/>}
             element={
              isLoggedIn ? (
                <Navigate to={`/Registration3/${destination}`} />
              ) : (
                <LoginPatil onSuccessfulLogin={handleSuccessfulLogin} />
              )
            }
              />*/}
            {/* <Route path="/LoginPatil"
             //element={<LoginPatil onSuccessfulLogin={handleSuccessfulLogin(vendorNo)}/>}
             element={

              !isLoggedIn ? (
                <LoginPatil onSuccessfulLogin={handleSuccessfulLogin} />
              ) : (vendStatus==="INITIATED" || vendStatus==="SAVED") :(
                <Navigate to={`/LoginPatil`} />
              )
                // <LoginPatil onSuccessfulLogin={handleSuccessfulLogin} />  
            }
              /> */}
            <Route
              path="/LoginPatil"
              element={
                !isLoggedIn ? (
                  <LoginPatil onSuccessfulLogin={handleSuccessfulLogin} />
                ) : (
                  (vendorStatus === "INITIATED" || vendorStatus === "SAVED") ? (
                    <VendorStatus />  // Replace with the appropriate component
                  ) : (
                    <Navigate to={`/Registration3/myhome`} />  // Replace with the appropriate component
                  )
                )
              }
            />
            <Route path="/DocumentSection" element={<FileUploadForm />} />
            <Route path="/VerificationSection" element={<VerificationTable />} />
            {/*<Route path="/VendorStatus" element={<VendorStatus />} />*/}
            {/*<Route path='/Registration3/myhome' element={<Registration3 />} /> */}
            {/* <Route path="/VendorStatus" element={
              isLoggedIn ? (
                (vendorStatus === "INITIATED" || vendorStatus === "SAVED") ? (
                  // <Registration3 />  // Replace with the appropriate component
                  navigate('/Registration3/myhome')
                ) : (
                  <VendorStatus />  // Replace with the appropriate component
                )
              ) : (
                <Navigate to={`/LoginPatil`} />
              )
            } />
            <Route path="/Registration3/myhome" element={
              isLoggedIn ? (
                (vendorStatus === "INITIATED" || vendorStatus === "SAVED") ? (
                  <Registration3 />  // Replace with the appropriate component
                ) : (
                  // <VendorStatus /> 
                  navigate('/VendorStatus')
                  // Replace with the appropriate component
                )
              ) : (
                <Navigate to={`/LoginPatil`} />
              )
            } /> */}
            <Route path="/VendorStatus" element={
              isLoggedIn ? (
                <VendorStatus />
              ) : (
                <Navigate to={`/LoginPatil`} />
              )
            } />
            <Route path="/Registration3/myhome" element={
              isLoggedIn ? (
                <Registration3 />
              ) : (
                <Navigate to={`/LoginPatil`} />
              )
            } />
          </Routes>
          {/*<LanguageSelection />*/}
        </EmailProvider>
      </VendorProvider>

      {/*</LanguageProvider>*/}

    </div>
  );
}
export default App;