import React, { useState, useEffect, useRef } from 'react';
//import { useParams } from 'react-router-dom';
//import { Navigate } from 'react-router-dom';
import './Registration3.css';
//import Select from 'react-select';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
//import PhoneInput from 'react-phone-input-2'
//import 'react-phone-input-2/lib/style.css'
import FileUploadForm from './FileUploadForm'
import { AccountCircle, AccountBalance, BusinessCenter } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import { useVendorContext } from './VendorContext';
import { useNavigate } from 'react-router-dom';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

//import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'; // can be used later
//import SupportAgentIcon from '@mui/icons-material/SupportAgent'; // can be used later

const Submit = () => {
  const { vendorNo } = useVendorContext();
  const navigate = useNavigate();
  console.log('Received vendorNo in Registration3:', vendorNo);

  let initialIntroductionData = {
    businessRegistration: '',
    vendorType: '',
    organisationType: '',
    organisationName: '',
    vendorPrimaryMobileCountryCode: '',
    vendorPrimaryMobileCountryCodeDescription: '',
    vendorPrimaryMobile: '',
    district: '',
    vendorPrimaryEmail: '',
    contactPersonSalutation: '',
    contactPersonFirstName: '',
    contactPersonLastName: '',
    contactPersonDesignation: '',
    registeredOfficeAddressCity: '',
    registeredOfficeAddressCountry: '',
    registeredOfficeAddressCountryDescription: '',
    registeredOfficeAddressPincode: '',
    registeredOfficeAddressState: '',
    registeredOfficeAddressStateDescription: '',
    registeredOfficeAddressBuilding: '',
    registeredOfficeAddressStreet: '',
    briefIntroduction: '',
    financeManagerName: '',
    financeManagerPhoneNumberCountryCode: '',
    financeManagerPhoneNumberCountryCodeDescription: '',
    financeManagerPhoneNumber: '',
    financeManagerEmail: '',
  }

  const initialIntroductionErrorMessages = {
    briefIntroductionError: '',
  }

  const initialKYVData = {
    countryOfTaxResidency: '',
    countryOfTaxResidencyLabel: '',
    dateOfIncorporation: '',
    PANNumber: '',
    typeOfWork: '',
    CINNumber: '',
    CINStatus: 'Filled',
    isCINNotRegisteredChecked: false,
    isCINNotApplicableChecked: false,
    GSTNumber: '',
    GSTStatus: 'Filled',
    isGSTNotApplicableChecked: false,
    isGSTNotRegisteredChecked: false,
    radioOption: '',
    udyamNumber: '',
    udyamPhoneNumber: '',
    udyamEmail: '',
    msmeNumber: '',
    msmePhoneNumber: '',
    otpRadioOption: '',
    udyamPhoneNumberOTP: '',
    udyamEmailOTP: '',
    msmePhoneNumberOTP: '',
    msmeEmail: '',
    msmeEmailOTP: '',
    EPFNumber: '',
    EPFStatus: 'Filled',
    isEPFNotApplicableChecked: false,
    isEPFNotRegisteredChecked: false,
    ESICNumber: '',
    ESICStatus: 'Filled',
    isESICNotApplicableChecked: false,
    isESICNotRegisteredChecked: false,
    ESICRelatedDistrict: '',
    TANNumberCountryCode: '',
    TANNumber: '',
    TANStatus: 'Filled',
    isTANNotApplicableChecked: false,
    TANMobileNumber: '',
  };

  const initialKYVErrorMessages = {
    PANError: '',
    CINError: '',
    GSTError: '',
    UDYAMError: '',
    MSMEError: '',
    EPFError: '',
    ESICError: '',
    TANError: ''
  };

  const initialbankAccountData = {
    bankAccountNumber: '',
    bankAccountHolderName: '',
    bankName: '',
    branchName: '',
    swiftCode: '',
    bankAddressStreet: '',
    bankAddressRegion: '',
    bankAddressCity: ''
  };

  const [saveData, setSaveData] = useState({
    isIntroductionData: false,
    isKYVData: false,
    isVerificationData: false,
    isBankData: false,
    // Add other data properties as needed
  });

  const { isIntroductionData, isKYVData, isBankData } = saveData;

  let [introductionData, setIntroductionData] = useState(initialIntroductionData);
  const [kyvData, setKYVData] = useState(initialKYVData);
  const [errorMessages, setErrorMessages] = useState(initialKYVErrorMessages);
  const [introductionErrorMessages, setIntroductionErrorMessages] = useState(initialIntroductionErrorMessages);
  const [bankAccountData, setBankAccountData] = useState(initialbankAccountData);
  //const [countries, setCountries] = useState([]);
  //let [selectedCountry, setSelectedCountry] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [data, setData] = useState([]);
  const [isIntroductionDataSaved, setIsIntroductionDataSaved] = useState(false);
  const [isIntroductionDataResetted, setIsIntroductionDataResetted] = useState(false);
  const [isKYVDataSaved, setIsKYVDataSaved] = useState(false);
  const [isKYVDataResetted, setIsKYVDataResetted] = useState(false);
  const [isBankDataSaved, setIsBankDataSaved] = useState(false);
  const [isBankDataResetted, setIsBankDataResetted] = useState(false);
  const [countryData, setCountryData] = useState([]);
  //const [stateData, setStateData] = useState([]);
  const [selectedAPICountryValue, setSelectedAPICountryValue] = useState('');
  const [selectedAPICountryOfTaxResidencyValue, setSelectedAPICountryOfTaxResidencyValue] = useState('');
  const [selectedAPIStateValue, setSelectedAPIStateValue] = useState('');
  const [selectedPrimaryVendorCountryCode, setSelectedPrimaryVendorCountryCode] = useState('');
  const [selectedManagerPhoneCountryCode, setSelectedManagerPhoneCountryCode] = useState('');
  //const [selectedPrimaryVendorCountryCode, setSelectedPrimaryVendorCountryCode] = useState('');
  const [isEditMode, setIsEditMode] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const [steps, setSteps] = useState([
    { id: 1, label: 'Vendor Details', icon: <BusinessCenter />, isCompleted: false },
    { id: 2, label: 'KYV Details', icon: <AccountCircle />, isCompleted: false },
    { id: 3, label: 'Bank Details', icon: <AccountBalance />, isCompleted: false },
    { id: 4, label: 'Documents', icon: <DescriptionIcon />, isCompleted: false },
  ]);

  // Function to add a step to the completedSteps array when a step is completed
  const markStepAsCompleted = (stepNumber) => {
    if (!completedSteps.includes(stepNumber)) {
      setCompletedSteps([...completedSteps, stepNumber]);
      setSteps((prevSteps) =>
        prevSteps.map((step) =>
          step.id === stepNumber ? { ...step, isCompleted: true } : step
        )
      );
    }
  };

  // Function to handle the click on a step in the Stepper
  const handleStepperClick = (stepNumber, bool) => {
    if (bool === false) {
      setCurrentStep(stepNumber);
      markStepAsCompleted(stepNumber);
    }

    else {
      // Check if all previous steps are completed
      const isAllPreviousStepsCompleted = steps
        .filter((step) => step.id <= stepNumber)
        .every((step) => completedSteps.includes(step.id));

      if (isAllPreviousStepsCompleted) {
        setCurrentStep(stepNumber);
        markStepAsCompleted(stepNumber); // Mark the current step as completed
      } else {
        // Show an error message or prevent navigation
      }
    }
  };

  const removeStepFromCompleted = (stepNumber) => {
    if (completedSteps.includes(stepNumber)) {
      setCompletedSteps(completedSteps.filter((step) => step !== stepNumber));
      setSteps((prevSteps) =>
        prevSteps.map((step) =>
          step.id === stepNumber ? { ...step, isCompleted: false } : step
        )
      );
    }
  };

  const username = 'SRIAABAP';
  const password = 'Patil@QA';
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  const [vendorNumber, setVendorNumber] = useState(0);
  const [companyCode, setCompanyCode] = useState(0);
  const [plant, setPlant] = useState(0);
  const [existingData, setExistingData] = useState(null);
  //const distinctCountryMap = new Map();
  const [distinctCountryMap, setDistinctCountryMap] = useState(new Map());
  const [distinctCountryCodeMap, setDistinctCountryCodeMap] = useState(new Map());
  const [loading, setLoading] = useState(false);
  // const [saving, setSaving] = useState(false);
  // const [fetching, setFetching] = useState(false);
  //const prevSharedData = useRef();
  const handleSetIntroductionData = (updatedData) => {
    setIntroductionData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };
  console.log("Vendor No from params is: ", vendorNo);
  //const auto_user_integer = parseInt(vendorNo);
  //let auto_user_int = String(auto_user_integer);
  const selectedCountryRef = useRef();
  const [sharedData, setSharedData] = useState(null);
  const [sharedCountryCodeData, setSharedCountryCodeData] = useState(null);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  useEffect(() => {
    if (!hasFetchedData) {
      const fetchData = async () => {
        //const currentBase64Credentials = base64Credentials;
        //const currentAutoUserInt = auto_user_int;
        const currentVendorNo = vendorNo;
        //const currentSaveData = saveData;
        try {

          if (!currentVendorNo) {
            // VendorNo is not available yet, skip the API call
            console.log('VendorNo is not available yet. Skipping API call.');
            return;
          }

          //const auto_user_value="0000000353";
          //console.log("auto_user_int value is:", auto_user_int);
          const apiUrl = `https://prilhanaqms01.patilgroup.com:44301/sap/bc/rest/zvendor/Vendor?sap-client=500&AUTO_USER=${vendorNo}`;
          setLoading(true);
          //const apiUrl = 'http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor?sap-client=200&VENDOR_NO=351';
          const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Authorization': 'Basic ' + base64Credentials,
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              mode: 'no-cors',
            },
          });
          // setLoading(false);

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const apiData = await response.json();
          setExistingData(apiData);
          setLoading(false);
          console.log("ApiData is: ", apiData);
          if (apiData.VEND_STATUS === "REQUESTED" || apiData.VEND_STATUS === "CREATED" || apiData.VEND_STATUS === "INAPP" || apiData.VEND_STATUS === "APPROVED") {
            setIsEditMode(false);
            handleStepperClick(1, false);
            handleStepperClick(2, false);
            handleStepperClick(3, false);
            handleStepperClick(4, false);
            setSaveData({ ...saveData, isIntroductionData: true, isKYVData: true, isVerificationData: true, isBankData: true });
          }
          let registeredOfficeCountry = apiData.COUNTRY;
          //let registeredOfficeCountryLabel = apiData.COUNTRY;
          let registeredOfficeState = apiData.STATE;
          let selectedCountryOfTaxReseidencyValue = apiData.COUNTRY_TAX_RESID;
          let vendorMobileCountryCode = apiData.TELEFTO;
          let managerMobileCountryCode = apiData.CNTRY_FIN_MANGR;
          console.log("registeredOfficeCountry: ", registeredOfficeCountry);
          console.log("registeredOfficeState: ", registeredOfficeState);
          console.log("countryOfTaxResidencyLabel: ", apiData.LANDX1);
          console.log("ESICStatus is: ", apiData.ESIC_STATUS);
          console.log("CINStatus is: ", apiData.CIN_STATUS);
          console.log("Date of Incop is: ", apiData.DATE_OF_INCORP);
          console.log("vendorType is: ", apiData.VENDOR_TYPE);
          setSelectedAPICountryOfTaxResidencyValue(selectedCountryOfTaxReseidencyValue);
          setSelectedAPICountryValue(registeredOfficeCountry);
          setSelectedAPIStateValue(registeredOfficeState);
          setSelectedPrimaryVendorCountryCode(vendorMobileCountryCode);
          setSelectedManagerPhoneCountryCode(managerMobileCountryCode);
          //setSelectedAPICountryValue(registeredOfficeCountry);
          handleSetIntroductionData({
            businessRegistration: apiData.BUTXT,
            vendorType: apiData.VENDOR_TYPE,
            organisationType: apiData.ORG_TYPE,
            organisationName: apiData.VENDOR_COMP_NAME,
            vendorPrimaryEmail: apiData.VENDOR_P_EMAIL,
            vendorPrimaryMobileCountryCode: apiData.TELEFTO,
            vendorPrimaryMobile: apiData.VENDOR_P_MOBNO.toString(),
            contactPersonSalutation: apiData.NAMEOFCONT_SAL,
            contactPersonFirstName: apiData.BU_SORT1_TXT,
            contactPersonLastName: apiData.BU_SORT2_TXT,
            contactPersonDesignation: apiData.CONT_PERSON_DES,
            registeredOfficeAddressPincode: apiData.POST_CODE1 === 0 ? '' : apiData.POST_CODE1.toString(),
            registeredOfficeAddressCity: apiData.CITY1,
            district: apiData.DISTRICT_REG,
            registeredOfficeAddressState: apiData.STATE,
            registeredOfficeAddressStateDescription: apiData.BEZEI,
            registeredOfficeAddressCountry: apiData.COUNTRY,
            registeredOfficeAddressCountryDescription: apiData.LANDX,
            registeredOfficeAddressBuilding: apiData.BUILDING_BLOCK,
            registeredOfficeAddressStreet: apiData.STREET,
            briefIntroduction: apiData.BRIEF_INTRO,
            financeManagerName: apiData.NAMEOF_FIN_MANGR,
            financeManagerEmail: apiData.EMAILOF_FIN_MANGR,
            financeManagerPhoneNumberCountryCode: apiData.CNTRY_FIN_MANGR,
            financeManagerPhoneNumber: apiData.PHNOOF_FIN_MANGR === 0 ? '' : apiData.PHNOOF_FIN_MANGR.toString(),
          });
          //console.log("parsed type is:",typeof(parseInt(introductionData.financeManagerPhoneNumber)));
          //let country = { value: apiData.COUNTRY_TAX_RESID, label: apiData.LANDX1 };
          //console.log("Country name is: apiData : ", country);
          setKYVData((prevData) => ({
            ...prevData,
            //countryOfTaxResidency: apiData.COUNTRY_TAX_RESID,
            //setSelectedCountry(country)
            countryOfTaxResidency: apiData.COUNTRY_TAX_RESID,
            countryOfTaxResidencyLabel: apiData.LANDX1,
            dateOfIncorporation: apiData.DATE_OF_INCORP,
            PANNumber: apiData.PANNO,
            typeOfWork: apiData.CAT_TYPE_OF_WORK,
            CINNumber: apiData.CIN_NUM,
            CINStatus: apiData.CIN_STATUS,
            GSTNumber: apiData.GSTNO,
            GSTStatus: apiData.GST_STATUS,
            radioOption: apiData.MSME_UDAYAM,
            udyamNumber: apiData.UDAYAM_NUM,
            msmeNumber: apiData.MSME_NUM,
            EPFNumber: apiData.EPF_NUM,
            EPFStatus: apiData.EPF_STATUS,
            ESICNumber: apiData.ESIC_NUM === 0 ? '' : apiData.ESIC_NUM,
            ESICRelatedDistrict: apiData.DISTRICT,
            ESICStatus: apiData.ESIC_STATUS,
            TANNumber: apiData.TAN_NUM,
            TANStatus: apiData.TAN_STATUS,
            //TANMobileNumber: apiData.TAN_MOBILE,
            TANMobileNumber: apiData.TAN_MOBILE === 0 ? '' : apiData.TAN_MOBILE.toString(),
          }));
          //console.log("Country Label in API Data is: ", apiData.LANDX1);
          console.log("API radio Data is: ", apiData.MSME_UDAYAM);
          //selectedCountryRef = useRef(selectedCountry);
          console.log("Selected Country in API is: ", apiData.COUNTRY_TAX_RESID);
          selectedCountryRef.current = apiData.COUNTRY_TAX_RESID;
          //setSelectedCountry(apiData.COUNTRY_TAX_RESID);
          //selectedCountry=apiData.COUNTRY_TAX_RESID;
          //console.log("Selected Country is: ",selectedCountry);

          setBankAccountData((prevData) => ({
            ...prevData,
            swiftCode: apiData.BANKL,
            bankName: apiData.BANKA,
            bankAccountHolderName: apiData.KOINH,
            bankAccountNumber: apiData.BANKN,
            branchName: apiData.BANK_BRANCH,
            bankAddress: apiData.BANK_ADDRESS,
            bankAddressStreet: apiData.STRAS,
            bankAddressRegion: apiData.PROVZ,
            bankAddressCity: apiData.ORT01,
          }));
          console.log("319");
          // Access the RAPI_CONT_STATE array
          const contStateArray = apiData.RAPI_CONT_STATE || [];
          console.log("apiData.RAPI_CONT_STATE", apiData.RAPI_CONT_STATE);
          setSharedData(contStateArray);

          const contCountryCodeArray = apiData.MOB_CODES || [];
          console.log("apiData.MOB_CODES", apiData.MOB_CODES);
          setSharedCountryCodeData(contCountryCodeArray);
          setVendorNumber(apiData.VENDOR_NO);

          setCompanyCode(apiData.BUKRS);

          setPlant(apiData.WERKS);
          console.log('Attempting API call with vendorNo:', vendorNo);
          //processCountryData(contStateArray, selectedAPICountryValue);
        } catch (error) {
          console.log("Ended up with and error and loading ")
          setLoading(false);
          if (error instanceof TypeError) {
            console.error('TypeError:', error.message);
            alert('Type Error :', error.message);
          } else if (error instanceof SyntaxError) {
            console.error('SyntaxError:', error.message);
            alert('SyntaxError:', error.message);
          } else {
            console.error('Unexpected error:', error);
            alert('Error while fetching data:', error);
          }
        }

        console.log('Attempting API after call with vendorNo:', vendorNo);
      };
      fetchData();
    }

  }, [vendorNo
    // , saveData, base64Credentials, hasFetchedData
  ]);

  useEffect(() => {
    if (sharedData
      //&& sharedData !== prevSharedData.current
    ) {
      const countryMap = new Map();
      sharedData.forEach(item => {
        countryMap.set(item.RAPI_CLANDX50, item.RAPI_CLAND1);
      });
      setDistinctCountryMap(countryMap);

      // Filter the data to get specific objects based on some condition
      const filteredData = sharedData.filter(item => item.RAPI_CLAND1 === selectedAPICountryValue);
      console.log("Filtered Data is: ", filteredData);
      setCountryData(filteredData);
      //prevSharedData.current = sharedData;
    }

  }, [sharedData, selectedAPICountryValue]);

  useEffect(() => {
    if (sharedCountryCodeData
      //&& sharedData !== prevSharedData.current
    ) {
      const countryMap = new Map();
      sharedCountryCodeData.forEach(item => {
        countryMap.set(item.MOB_COUNTRY, item.TELEFTO);
      });
      setDistinctCountryCodeMap(countryMap);
    }
  }, [sharedCountryCodeData]);

  const handleSelectCountryCodeChange = (event) => {
    const selectedCountryCodeValue = event.target.value;
    const name = event.target.name;
    const selectedCountryCodeDescription = event.target.options[event.target.selectedIndex].text;
    if (name === "vendorPrimaryMobileCountryCode") {
      setSelectedPrimaryVendorCountryCode(selectedCountryCodeValue);
      setIntroductionData({
        ...introductionData,
        vendorPrimaryMobileCountryCode: selectedCountryCodeValue,
        vendorPrimaryMobileCountryCodeDescription: selectedCountryCodeDescription,
      });

      console.log("Selected Country from API is: ", selectedCountryCodeValue);
      console.log(
        "introductionData.vendorPrimaryMobileCountryCode is: ",
        introductionData.vendorPrimaryMobileCountryCode
      );
      console.log(
        "introductionData.vendorPrimaryMobileCountryCode is: ",
        introductionData.vendorPrimaryMobileCountryCodeDescription
      );
    }
    else if (name === "financeManagerPhoneNumberCountryCode") {
      setSelectedManagerPhoneCountryCode(selectedCountryCodeValue);
      setIntroductionData({
        ...introductionData,
        financeManagerPhoneNumberCountryCode: selectedCountryCodeValue,
        financeManagerPhoneNumberCountryCodeDescription: selectedCountryCodeDescription,
      });
      console.log(
        "introductionData.financeManagerPhoneNumberCountryCode is: ",
        introductionData.financeManagerPhoneNumberCountryCode
      );
      console.log(
        "introductionData.financeManagerPhoneNumberCountryCodeDescription is: ",
        introductionData.financeManagerPhoneNumberCountryCodeDescription
      );
    }
  };

  const handleSelectCountryChange = (event) => {
    const selectedCountryValue = event.target.value;
    const selectedCountryDescription =
      event.target.options[event.target.selectedIndex].text;

    setSelectedAPICountryValue(selectedCountryValue);
    setIntroductionData({
      ...introductionData,
      registeredOfficeAddressCountry: selectedCountryValue,
      registeredOfficeAddressCountryDescription: selectedCountryDescription,
    });

    console.log("Selected Country from API is: ", selectedCountryValue);
    console.log(
      "introductionData.registeredOfficeAddressCountry is: ",
      introductionData.registeredOfficeAddressCountry
    );
    console.log(
      "introductionData.registeredOfficeAddressCountryDescription is: ",
      introductionData.registeredOfficeAddressCountryDescription
    );
  };

  const handleSelectCountryOfTaxResidencyChange = (event) => {
    const selectedCountryOfTaxResidencyValue = event.target.value;
    const selectedCountryOfTaxResidencyDescription =
      event.target.options[event.target.selectedIndex].text;

    setSelectedAPICountryOfTaxResidencyValue(selectedCountryOfTaxResidencyValue);
    setKYVData({
      ...kyvData,
      countryOfTaxResidency: selectedCountryOfTaxResidencyValue,
      countryOfTaxResidencyLabel: selectedCountryOfTaxResidencyDescription,
    });

    console.log("Selected Country of Tax Residency from API is: ", selectedCountryOfTaxResidencyValue);
    console.log(
      "kyvData.countryOfTaxResidency is: ",
      kyvData.countryOfTaxResidency
    );
    console.log(
      "kyvData.countryOfTaxResidencyDescription is: ",
      kyvData.countryOfTaxResidencyLabel
    );
  };

  const handleSelectStateChange = (event) => {
    const selectedStateValue = event.target.value;
    const selectedStateDescription =
      event.target.options[event.target.selectedIndex].text;

    setSelectedAPIStateValue(selectedStateValue);
    setIntroductionData({
      ...introductionData,
      registeredOfficeAddressState: selectedStateValue,
      registeredOfficeAddressStateDescription: selectedStateDescription,
    });

    console.log("Selected State from API is: ", selectedStateValue);
    console.log(
      "introductionData.registeredOfficeAddressState is: ",
      introductionData.registeredOfficeAddressState
    );
    console.log(
      "introductionData.registeredOfficeAddressStateDescription is: ",
      introductionData.registeredOfficeAddressStateDescription
    );
  };

  const fetchDataFromApi = async () => {
    const apiUrl = `https://prilhanaqms01.patilgroup.com:44301/sap/bc/rest/zvendor/Vendor?sap-client=500&AUTO_USER=${vendorNo}`;
    console.log("This is existing Data: ");
    console.log(existingData);

    const updatedFields = {
      ...existingData,
      // Start of Introduction Data
      BUTXT: introductionData.businessRegistration,
      VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
      TELEFTO: introductionData.vendorPrimaryMobileCountryCode,
      VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
      VENDOR_TYPE: introductionData.vendorType,
      VENDOR_NO: vendorNumber,
      ORG_TYPE: introductionData.organisationType,
      VENDOR_COMP_NAME: introductionData.organisationName,
      NAMEOFCONT_SAL: introductionData.contactPersonSalutation,
      BU_SORT1_TXT: introductionData.contactPersonFirstName,
      BU_SORT2_TXT: introductionData.contactPersonLastName,
      CONT_PERSON_DES: introductionData.contactPersonDesignation,
      POST_CODE1: parseInt(introductionData.registeredOfficeAddressPincode),
      CITY1: introductionData.registeredOfficeAddressCity,
      DISTRICT_REG: introductionData.district,
      STATE: introductionData.registeredOfficeAddressState, // In SAP DB State is saved as Region
      BEZEI: introductionData.registeredOfficeAddressStateDescription,
      COUNTRY: introductionData.registeredOfficeAddressCountry,
      LANDX: introductionData.registeredOfficeAddressCountryDescription,
      BUILDING_BLOCK: introductionData.registeredOfficeAddressBuilding,
      STREET: introductionData.registeredOfficeAddressStreet,
      BRIEF_INTRO: introductionData.briefIntroduction,
      NAMEOF_FIN_MANGR: introductionData.financeManagerName,
      CNTRY_FIN_MANGR: introductionData.financeManagerPhoneNumberCountryCode,
      PHNOOF_FIN_MANGR: parseInt(introductionData.financeManagerPhoneNumber),
      EMAILOF_FIN_MANGR: introductionData.financeManagerEmail,
      // End of Introduction Data

      // Start of KYV Details
      COUNTRY_TAX_RESID: kyvData.countryOfTaxResidency,
      LANDX1: kyvData.countryOfTaxResidencyLabel,
      DATE_OF_INCORP: kyvData.dateOfIncorporation,
      PANNO: kyvData.PANNumber,
      CAT_TYPE_OF_WORK: kyvData.typeOfWork,
      CIN_NUM: kyvData.CINNumber,
      CIN_STATUS: kyvData.CINStatus,
      GSTNO: kyvData.GSTNumber,
      GST_STATUS: kyvData.GSTStatus,
      MSME_UDAYAM: kyvData.radioOption,
      UDAYAM_NUM: kyvData.udyamNumber,
      MSME_NUM: kyvData.msmeNumber,
      EPF_NUM: kyvData.EPFNumber,
      EPF_STATUS: kyvData.EPFStatus,
      ESIC_NUM: kyvData.ESICNumber,
      DISTRICT: kyvData.ESICRelatedDistrict,
      ESIC_STATUS: kyvData.ESICStatus,
      TAN_NUM: kyvData.TANNumber,
      TAN_STATUS: kyvData.TANStatus,
      // TAN_MOBILE: kyvData.TANMobileNumber,
      TAN_MOBILE: parseInt(kyvData.TANMobileNumber),
      // End of KYV Details

      // Start of Bank Information Data
      BANKL: bankAccountData.swiftCode,
      BANKA: bankAccountData.bankName,
      KOINH: bankAccountData.bankAccountHolderName,
      BANKN: bankAccountData.bankAccountNumber,
      BANK_BRANCH: bankAccountData.branchName,
      BANK_ADDRESS: bankAccountData.bankAddress,
      STRAS: bankAccountData.bankAddressStreet,
      PROVZ: bankAccountData.bankAddressRegion,
      ORT01: bankAccountData.bankAddressCity,
      //End of Bank Information Data
    };

    const updatedData = { ...existingData, ...updatedFields };

    const apiData = {
      ...updatedData,
      WERKS: plant,
      BUKRS: companyCode,
      VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
      VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
    };
    console.log("api data after submission: , apiData");
    console.log("introduction Data is:", introductionData);
    console.log("introduction data fin man phn type", typeof (introductionData.financeManagerPhoneNumber));
    console.log("fin man api data type", typeof (apiData.PHNOOF_FIN_MANGR));
    //console.log("Country Label in API Data is: ", apiData.LANDX1);

    // try {
    //   const response = await fetch(apiUrl, {
    //     method: 'PUT',
    //     headers: {
    //       'Authorization': 'Basic ' + base64Credentials,
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(apiData),
    //   })
    //   .then((response) => response.json())
    //   console.log("Response Status is: ", response.status);
    //   if (!response.ok) {
    //     throw new Error('Network response was not ok');
    //   }
    //   console.log("Response Status is: ", response.status);
    //   // console.log("Response JSON is: ", response.json());
    //   // if (response.status !== 200) {
    //   //   throw new Error('Network response was not ok');
    //   // }

    //   // const responseData = await response.json();
    //   const responseData = await response.json();
    //   setExistingData(responseData);
    //   setData(responseData);
    //   setIntroductionData({
        // businessRegistration: existingData.businessRegistration || '',
        // vendorType: existingData.VENDOR_TYPE || '',
        // organisationType: existingData.ORG_TYPE || '',
        // organisationName: existingData.VENDOR_COMP_NAME || '',
        // contactPersonSalutation: existingData.NAMEOFCONT_SAL || '',
        // contactPersonFirstName: existingData.BU_SORT1_TXT || '',
        // contactPersonLastName: existingData.BU_SORT2_TXT || '',
        // contactPersonDesignation: existingData.CONT_PERSON_DES || '',
        // registeredOfficeAddressPincode: existingData.POST_CODE1 || '',
        // registeredOfficeAddressCity: existingData.CITY1 || '',
        // district: existingData.DISTRICT_REG || '',
        // registeredOfficeAddressState: existingData.STATE || '',
        // registeredOfficeAddressStateDescription: existingData.BEZEI || '',
        // registeredOfficeAddressCountry: existingData.COUNTRY || '',
        // registeredOfficeAddressCountryDescription: existingData.COUNTRY || '',
        // registeredOfficeAddressBuilding: existingData.BUILDING_BLOCK || '',
        // registeredOfficeAddressStreet: existingData.STREET || '',
        // briefIntroduction: existingData.BRIEF_INTRO || '',
        // financeManagerName: existingData.NAMEOF_FIN_MANGR || '',
        // financeManagerEmail: existingData.EMAILOF_FIN_MANGR || '',
        // financeManagerPhoneNumberCountryCode: existingData.CNTRY_FIN_MANGR || '',
        // financeManagerPhoneNumber: existingData.PHNOOF_FIN_MANGR || '',
    //   })
    //   console.log("Existing Data is:", existingData);
    //   navigate(`/VendorStatus`);
    // } catch (error) {
    //   console.log(error.message);
    //   console.error('Error fetching data:', error);
    //   alert('Error fetching data:', error);
    // }

    fetch(apiUrl, {
      method: 'PUT',
      headers: {
        'Authorization': 'Basic ' + base64Credentials,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiData),
    })
      .then((response) => {
        console.log("Response Status is: ", response.status);
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        return response.json();
      })
      .then((responseData) => {
        setExistingData(responseData);
        setData(responseData);
    
        // Set Introduction Data as per your existing logic
        setIntroductionData({
          businessRegistration: existingData.businessRegistration || '',
        vendorType: existingData.VENDOR_TYPE || '',
        organisationType: existingData.ORG_TYPE || '',
        organisationName: existingData.VENDOR_COMP_NAME || '',
        contactPersonSalutation: existingData.NAMEOFCONT_SAL || '',
        contactPersonFirstName: existingData.BU_SORT1_TXT || '',
        contactPersonLastName: existingData.BU_SORT2_TXT || '',
        contactPersonDesignation: existingData.CONT_PERSON_DES || '',
        registeredOfficeAddressPincode: existingData.POST_CODE1 || '',
        registeredOfficeAddressCity: existingData.CITY1 || '',
        district: existingData.DISTRICT_REG || '',
        registeredOfficeAddressState: existingData.STATE || '',
        registeredOfficeAddressStateDescription: existingData.BEZEI || '',
        registeredOfficeAddressCountry: existingData.COUNTRY || '',
        registeredOfficeAddressCountryDescription: existingData.COUNTRY || '',
        registeredOfficeAddressBuilding: existingData.BUILDING_BLOCK || '',
        registeredOfficeAddressStreet: existingData.STREET || '',
        briefIntroduction: existingData.BRIEF_INTRO || '',
        financeManagerName: existingData.NAMEOF_FIN_MANGR || '',
        financeManagerEmail: existingData.EMAILOF_FIN_MANGR || '',
        financeManagerPhoneNumberCountryCode: existingData.CNTRY_FIN_MANGR || '',
        financeManagerPhoneNumber: existingData.PHNOOF_FIN_MANGR || '',
        });
    
        console.log("Existing Data is:", existingData);
        navigate(`/VendorStatus`);
      })
      .catch((error) => {
        console.log(error.message);
        console.error('Error fetching data:', error);
        alert('Error fetching data:', error);
      });

      
    // fetch(apiUrl, {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Basic ' + base64Credentials,
    //   },
    //   body: JSON.stringify(apiData),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log('Submitted Successfully : ', apiData);
    //     setSaveData({ ...saveData, isIntroductionData: true });
    //     console.log(data);
    //     // setSaving(false);
    //   })
    //   .catch((error) => {
    //     console.error('Error saving data:', error);
    //     // Handle error, show a message to the user, etc.
    //   });
  };

  const handleSubmission = () => {
    console.log("Value of isEditMode is: ", isEditMode);
    fetchDataFromApi();
  };

  const saveIntroductionDataToDatabase = () => {
    // Replace this URL with your actual server endpoint
    //const apiUrl = 'http://10.10.121.116:8001/sap/bc/rest/zvendor/Vendor?sap-client=500';
    setTimeout(() => {
      // Set the state to indicate that data is saved successfully
      setIsIntroductionDataSaved(true);

      // After 5 seconds (adjust the delay as needed), reset the state to hide the message
      setTimeout(() => {
        setIsIntroductionDataSaved(false);
      }, 5000);
    }, 1000);

    console.log("Clicked on SAVE Button in Introduction Data");
    console.log("Intro as:", introductionData);
    const updatedFields = {
      BUTXT: introductionData.businessRegistration,
      VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
      TELEFTO: introductionData.vendorPrimaryMobileCountryCode,
      VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
      VENDOR_TYPE: introductionData.vendorType,
      VENDOR_NO: vendorNumber,
      ORG_TYPE: introductionData.organisationType,
      VENDOR_COMP_NAME: introductionData.organisationName,
      NAMEOFCONT_SAL: introductionData.contactPersonSalutation,
      BU_SORT1_TXT: introductionData.contactPersonFirstName,
      BU_SORT2_TXT: introductionData.contactPersonLastName,
      CONT_PERSON_DES: introductionData.contactPersonDesignation,
      POST_CODE1: parseInt(introductionData.registeredOfficeAddressPincode),
      CITY1: introductionData.registeredOfficeAddressCity,
      DISTRICT_REG: introductionData.district,
      STATE: introductionData.registeredOfficeAddressState, // In SAP DB State is saved as Region
      BEZEI: introductionData.registeredOfficeAddressStateDescription,
      COUNTRY: introductionData.registeredOfficeAddressCountry,
      LANDX: introductionData.registeredOfficeAddressCountryDescription,
      BUILDING_BLOCK: introductionData.registeredOfficeAddressBuilding,
      STREET: introductionData.registeredOfficeAddressStreet,
      BRIEF_INTRO: introductionData.briefIntroduction,
      NAMEOF_FIN_MANGR: introductionData.financeManagerName,
      CNTRY_FIN_MANGR: introductionData.financeManagerPhoneNumberCountryCode,
      PHNOOF_FIN_MANGR: parseInt(introductionData.financeManagerPhoneNumber),
      EMAILOF_FIN_MANGR: introductionData.financeManagerEmail,
    };
    console.log("Existing Data is: 1 : ", existingData);
    // Combine existing data with the updated fields
    const apiData = {
      ...existingData,
      ...updatedFields,
      BUKRS: companyCode,
      WERKS: plant,
      VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
      VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
    };
    // setSaving(true);
    console.log(apiData);
    setExistingData(apiData);
    const apiUrl = `https://prilhanaqms01.patilgroup.com:44301/sap/bc/rest/zvendor/Vendor_PRIPL?sap-client=500&AUTO_USER=${vendorNo}`;
    // Simulate an API call to save data
    fetch(apiUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + base64Credentials,
      },
      body: JSON.stringify(apiData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Data saved successfully:', apiData);
        setSaveData({ ...saveData, isIntroductionData: true });
        console.log(data);
        // setSaving(false);
      })
      .catch((error) => {
        console.error('Error saving data:', error);
        // Handle error, show a message to the user, etc.
      });
    // .then(() => {
    //   setLoading(false);
    // });
  };

  const hasErrorMessages = () => {
    return Object.values(errorMessages).some((errorMessage) => errorMessage !== '');
  };

  const saveKYVDataToDatabase = () => {
    // Replace this URL with your actual server endpoint
    //const apiUrl = 'http://10.10.121.116:8001/sap/bc/rest/zvendor/Vendor?sap-client=200';

    if (hasErrorMessages()) {
      // Display a message to the user or handle it appropriately
      alert("Cannot Save KYV Data. Please Enter Valid Data.");
      return;
    }

    console.log("KYV Data: ", kyvData);
    const updatedFields = {
      COUNTRY_TAX_RESID: kyvData.countryOfTaxResidency,
      LANDX1: kyvData.countryOfTaxResidencyLabel,
      //COUNTRY_TAX_RESID: selectedCountry,
      DATE_OF_INCORP: kyvData.dateOfIncorporation,
      PANNO: kyvData.PANNumber,
      CAT_TYPE_OF_WORK: kyvData.typeOfWork,
      CIN_NUM: kyvData.CINNumber,
      CIN_STATUS: kyvData.CINStatus,
      GSTNO: kyvData.GSTNumber,
      GST_STATUS: kyvData.GSTStatus,
      MSME_UDAYAM: kyvData.radioOption,
      UDAYAM_NUM: kyvData.udyamNumber,
      MSME_NUM: kyvData.msmeNumber,
      EPF_NUM: kyvData.EPFNumber,
      EPF_STATUS: kyvData.EPFStatus,
      ESIC_NUM: kyvData.ESICNumber,
      ESIC_STATUS: kyvData.ESICStatus,
      DISTRICT: kyvData.ESICRelatedDistrict,
      TAN_NUM: kyvData.TANNumber,
      TAN_STATUS: kyvData.TANStatus,
      //TAN_MOBILE: kyvData.TANMobileNumber,
      TAN_MOBILE: parseInt(kyvData.TANMobileNumber),
    }
    console.log("Existing Data is: 2 : ", existingData);

    // Combine existing data with the updated fields
    const apiData = {
      ...existingData,
      ...updatedFields,
      BUKRS: companyCode,
      WERKS: plant,
      VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
      VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
    };
    console.log("KYV Data1", apiData);
    setExistingData(apiData);

    // Simulate an API call to save data
    // setSaving(true);
    setTimeout(() => {
      setIsKYVDataSaved(true);
      // After 5 seconds (adjust the delay as needed), reset the state to hide the message
      setTimeout(() => {
        setIsKYVDataSaved(false);
      }, 5000);
    }, 1000);
    const apiUrl = `https://prilhanaqms01.patilgroup.com:44301/sap/bc/rest/zvendor/Vendor_PRIPL?sap-client=500&AUTO_USER=${vendorNo}`;
    fetch(apiUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + base64Credentials,
      },
      body: JSON.stringify(apiData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('KYV Data saved successfully:', data);
        setSaveData({ ...saveData, isKYVData: true });
        // setSaving(false);
        // You can add additional logic after successful data save
      })
      .catch((error) => {
        console.error('Error saving data:', error);
        // Handle error, show a message to the user, etc.
      });
    console.log("CINReg", kyvData.isCINNotRegisteredChecked);
    console.log("CINApp", kyvData.isCINNotApplicableChecked);
    console.log(kyvData);
    console.log("KYV Data is : ", apiData);
  };

  const saveBankInformationDataToDatabase = () => {
    // Replace this URL with your actual server endpoint
    //const apiUrl = 'http://10.10.121.116:8001/sap/bc/rest/zvendor/Vendor?sap-client=500';
    setTimeout(() => {
      setIsBankDataSaved(true);
      // After 5 seconds (adjust the delay as needed), reset the state to hide the message
      setTimeout(() => {
        setIsBankDataSaved(false);
      }, 5000);
    }, 1000);

    const updatedFields = {
      BANKL: bankAccountData.swiftCode,
      BANKA: bankAccountData.bankName,
      KOINH: bankAccountData.bankAccountHolderName,
      BANKN: bankAccountData.bankAccountNumber,
      BANK_BRANCH: bankAccountData.branchName,
      BANK_ADDRESS: bankAccountData.bankAddress,
      STRAS: bankAccountData.bankAddressStreet,
      PROVZ: bankAccountData.bankAddressRegion,
      ORT01: bankAccountData.bankAddressCity,
    }

    // Combine existing data with the updated fields
    console.log("Existing Data is: 4 : ", existingData);

    const apiData = {
      ...existingData,
      ...updatedFields,
      BUKRS: companyCode,
      WERKS: plant,
      VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
      VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
    };
    setExistingData(apiData);
    // setSaving(true);
    // Simulate an API call to save data
    const apiUrl = `https://prilhanaqms01.patilgroup.com:44301/sap/bc/rest/zvendor/Vendor_PRIPL?sap-client=500&AUTO_USER=${vendorNo}`;
    fetch(apiUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + base64Credentials,
      },
      body: JSON.stringify(apiData),
    })
      .then((response) => {response.json(); console.log("Response Status is: ", response.status);})
      .then((data) => {
        setSaveData({ ...saveData, isBankData: true });
        // console.log("Response Status is: ", response.status);
        console.log('Data saved successfully:', data);
        // setSaving(false);
        // You can add additional logic after successful data save
      })
      .catch((error) => {
        console.error('Error saving data:', error);
        // Handle error, show a message to the user, etc.
      });
  };

  const displayLabels = {
    '': 'Select Option',
    '01': 'Registered',
    '02': 'URD',
    '03': 'WC',
    '04': 'End Customer',
    '05': 'Govt',
  };

  const handleIntroductionInputChange = (event) => {
    if (event.target && event.target.id) {
      const { name, value } = event.target;
      if(name==="registeredOfficeAddressCity" || name==="district") {
        if (/^[a-zA-Z\s]*$/.test(value) || value === "") {
          setIntroductionData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      }
      else {
      setIntroductionData(
        { ...introductionData, [name]: value });
      //console.log("Setted Data", name, " as ", value);
    } 
  }/*else {
      setIntroductionData({ ...introductionData, vendorPrimaryMobile: String(event) });
    }*/
  }

  const validateEmail = (email) => {
    console.log('Validating email:', email);
    const isValid = email.includes('@');
    console.log('Is valid email:', isValid, "email len is: ", email.length);
    setIsValidEmail(isValid);
    if (email.length === 0) {
      const isVal = (email.length === 0);
      setIsValidEmail(isVal);
    }
  };

  const handleEmailBlur = (event) => {
    const { name, value } = event.target;

    if (name === 'financeManagerEmail') {
      validateEmail(value);
    }
  };

  const handlePinCodeChange = (event) => {
    if (event.target && event.target.id) {
      const { name, value } = event.target;
      const newPinCode = value;
      // Use a regular expression to allow only numeric values (0-9)
      const numericPinCode = newPinCode.replace(/[^0-9]/g, '');
      setIntroductionData(
        { ...introductionData, [name]: numericPinCode });
      //console.log("Setted Data", name, " as ", value);
    }
  }

  const handlePhoneInputChange = (event) => {
    if (event.target && event.target.id) {
      const { name, value } = event.target;
      console.log("Name is : ", name, "type of name is: ", typeof (name), "and Value is : ", value);
      const newPhoneNumber = value;
      if (name === "financeManagerPhoneNumber") {
        // Use a regular expression to allow only numeric values (0-9)
        const numericPhoneNumber = newPhoneNumber.replace(/[^0-9]/g, '');
        setIntroductionData(
          { ...introductionData, [name]: numericPhoneNumber });
        //console.log("Setted Data", name, " as ", value);
      }

      else if (name === "TANMobileNumber") {
        const numericPhoneNumber = newPhoneNumber.replace(/[^0-9]/g, '');
        setKYVData(
          { ...kyvData, [name]: numericPhoneNumber });
      }
    }
  }

  /*const handleIntroductionPhoneInputChange = (event) => {
    setIntroductionData({ ...introductionData, financeManagerPhoneNumber: String(event) });
  };*/

  /*const handleKYVPhoneInputChange = (event) => {
    setKYVData({ ...kyvData, TANMobileNumber: event });
  };*/

  const formatDateToDMY = (isoDate) => {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-based
    const year = date.getFullYear();

    // Ensure leading zeros for day and month if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleKYVInputChange = (event) => {
    if (event.target && event.target.name) {
      const { name, value, type, checked } = event.target;
      //const isCINDisabled = type === 'checkbox' ? checked : value;
      if (name === "PANNumber") {
        // Convert the input to uppercase
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();
        // Check for PANNumber format (5 alphabets, 4 numbers, 1 alphabet)
        const panRegex = /^[A-Z]{5}\d{4}[A-Z]$/;
        if (panRegex.test(formattedValue)) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            PANError: '',
          }));
        }

        /*else if (formattedValue.length === 0) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            PANError: '',
          }));
        }*/

        else if (formattedValue.length === 10) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            PANError: 'Invalid PAN format. Please enter a valid PAN. Ex: ABCTY1234D',
          }));
        }
        else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            PANError: 'Not as PAN Format',
          }));
        }
      }

      /*else if (name === "CINNumber") {
        // Convert the input to uppercase
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();
        console.log(formattedValue + " not condition");
        // Check for CINNumber format (21 characters, alphanumeric)
        const cinRegex = /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
        if (cinRegex.test(formattedValue)) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
            CINStatus: "Filled"
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            CINError: '',
          }));
        } else if (formattedValue.length === 21) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            CINError: 'Invalid CINNumber format. Please use the standard format, eg: L12345KA2023PLC123456'
          }));
        } else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            CINError: 'Not as CIN Number Format. Ex: L12345KA2023PLC123456'
          }));
        }
      }*/

      // ... (your existing code)

      else if (name === "CINNumber") {
        // Convert the input to uppercase
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();
        console.log(formattedValue + " not condition");

        // Check if dateOfIncorporation is not filled
        if (!kyvData.dateOfIncorporation) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            CINError: 'Please fill in the date of incorporation first.',
          }));
        } else {
          // Check for CINNumber format (21 characters, alphanumeric)
          const cinRegex = /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
          if (cinRegex.test(formattedValue)) {
            // Extract year from dateOfIncorporation and compare with CINNumber
            const yearFromCIN = formattedValue.substring(8, 12);
            const yearFromDateOfIncorporation = kyvData.dateOfIncorporation.substring(0, 4);
            console.log("CIN year is: ", yearFromCIN, "Date of Incop year is: ", yearFromDateOfIncorporation);

            if (yearFromCIN === yearFromDateOfIncorporation) {
              setKYVData((prevState) => ({
                ...prevState,
                [name]: formattedValue,
                CINStatus: "Filled"
              }));
              setErrorMessages((prevErrors) => ({
                ...prevErrors,
                CINError: '',
              }));
            } else {
              setKYVData((prevState) => ({
                ...prevState,
                [name]: formattedValue,
              }));
              setErrorMessages((prevErrors) => ({
                ...prevErrors,
                CINError: 'Year in CINNumber does not match the year in dateOfIncorporation.',
              }));
            }
          } else if (formattedValue.length === 21) {
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              CINError: 'Invalid CINNumber format. Please use the standard format, eg: L12345KA2023PLC123456'
            }));
          } else {
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              CINError: 'Not as CIN Number Format. Ex: L12345KA2023PLC123456'
            }));
          }
        }
      }


      /*else if (name === "GSTNumber") {
        // Convert the input to uppercase
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();
        // Check for PANNumber format (5 alphabets, 4 numbers, 1 alphabet)
        const panRegex = /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})$/;
        if (panRegex.test(formattedValue)) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
            GSTStatus: "Filled"
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: '',
          }));
        }

        

        else if (formattedValue.length === 15) {
          const fourteenthCharacter = formattedValue.charAt(13);

          if (fourteenthCharacter === 'Z') {
            // The 14th character is 'Z', so the GST number is valid.
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              GSTError: 'Enter Valid GST Number', // No error message because the GST number is valid.
            }));
          } else {
            // The 14th character is not 'Z', so it's an invalid GST number.
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              GSTError: 'Invalid GST format. The 14th character should be Z.',
            }));
          }
        }

        else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: 'Not as GST Format',
          }));
        }
      }*/

      else if (name === "GSTNumber") {
        // Convert the input to uppercase
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();

        // Check if Registered Address Office State Filled/ not
        if (!introductionData.registeredOfficeAddressState) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: 'Kindly fill State in Introduction Data Tab, then fill GST Number.',
          }));
        }

        else if (!kyvData.PANNumber) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: 'Kindly fill PAN Number first, then fill GST.',
          }));
        }

        else if (kyvData.PANNumber.length !== 10) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: 'Please fill PAN Completely, then fill GST.',
          }));
        }

        else if (kyvData.PANNumber.length === 10 && errorMessages.PANError !== "") {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: 'Please fill PAN Number Correctly.',
          }));
        }

        else {
          // Check for PANNumber format (5 alphabets, 4 numbers, 1 alphabet)
          const panRegex = /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})/;
          const extractedPAN = formattedValue.substring(2, 12);
          const extractedStateCode = formattedValue.substring(0, 2);

          if (panRegex.test(formattedValue)) {
            // Extract the PAN number from the GST
            // Check if extracted PAN matches the provided PAN
            if (extractedPAN === kyvData.PANNumber && extractedStateCode === introductionData.registeredOfficeAddressState) {
              setKYVData((prevState) => ({
                ...prevState,
                [name]: formattedValue,
                GSTStatus: "Filled"
              }));
              setErrorMessages((prevErrors) => ({
                ...prevErrors,
                GSTError: '',
              }));
            }
          } else if (formattedValue.length === 15) {
            const fourteenthCharacter = formattedValue.charAt(13);

            if (fourteenthCharacter === 'Z') {
              // The 14th character is 'Z', so the GST number is valid.
              setKYVData((prevState) => ({
                ...prevState,
                [name]: formattedValue,
              }));
              setErrorMessages((prevErrors) => ({
                ...prevErrors,
                GSTError: '',
              }));
            }
            else if (extractedStateCode !== introductionData.registeredOfficeAddressState) {
              setKYVData((prevState) => ({
                ...prevState,
                [name]: formattedValue,
              }));
              setErrorMessages((prevErrors) => ({
                ...prevErrors,
                GSTError: 'Invalid State Code in GST. Registered Office State Code is not same as provided one.',
              }));
            }
            else if (extractedPAN !== kyvData.PANNumber) {
              setKYVData((prevState) => ({
                ...prevState,
                [name]: formattedValue,
              }));
              setErrorMessages((prevErrors) => ({
                ...prevErrors,
                GSTError: 'Invalid PAN in GST. PAN in GST should match the provided PAN number.',
              }));
            }
            else {
              // The 14th character is not 'Z', so it's an invalid GST number.
              setKYVData((prevState) => ({
                ...prevState,
                [name]: formattedValue,
              }));
              setErrorMessages((prevErrors) => ({
                ...prevErrors,
                GSTError: 'Invalid GST format. The 14th character should be Z.',
              }));
            }
          } else {
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              GSTError: 'Not as GST Format',
            }));
          }
        }
      }

      else if (name === "msmeNumber") {
        // Convert the input to uppercase
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();
        // Check for udyamNumber format (21 characters, alphanumeric)
        const cinRegex = /[UDYAM][A-Z]{2}[00][0-9]{7}/;
        if (cinRegex.test(formattedValue)) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            UDYAMError: '',
          }));
        } else if (formattedValue.length === 16) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            UDYAMError: 'Invalid PAN format. Please enter a valid PAN. Ex: UDYAMAB001234567'
          }));
        } else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            UDYAMError: 'Not as UDYAM Number Format. Ex: UDYAMAB001234567'
          }));
        }
      }

      else if (name === "udyamNumber") {
        // Convert the input to uppercase
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();
        // Check for UDYAMNumber format (21 characters, alphanumeric)
        //const cinRegex = /^[UDYAM][A-Z]{2}[00][0-9]{7}/;
        const cinRegex = /^UDYAM-[A-Z]{2}-\d{2}-\d{7}$/;
        if (cinRegex.test(formattedValue)) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            UDYAMError: '',
          }));
        } else if (formattedValue.length === 19) {
          if (formattedValue.substring(0, 6) !== "UDYAM-") {
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              UDYAMError: 'First six Characters should be UDYAM-'
            }));
          }
          else {
            setKYVData((prevState) => ({
              ...prevState,
              [name]: formattedValue,
            }));
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              UDYAMError: 'Invalid UDYAM Number. Please Enter Valid one'
            }));
          }
        } else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            UDYAMError: 'Not as UDYAM Number Format. Ex: UDYAM-AB-00-1234567'
          }));
        }
      }

      else if (name === "EPFNumber") {
        // Convert the input to uppercase
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();
        // Check for udyamNumber format (22 characters, alphanumeric)
        const cinRegex = /^[A-Z]{5}[0-9]{17}/;
        if (cinRegex.test(formattedValue)) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
            EPFStatus: "Filled"
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            EPFError: '',
          }));
        } else if (formattedValue.length === 22) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            EPFError: 'Invalid EPF Number format. Please enter a valid EPF Number. Ex: CCROC00112271234455662'
          }));
        } else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            EPFError: 'Not as EPF Number Format. Ex: CCROC00112271234455662'
          }));
        }
      }

      else if (name === "ESICNumber") {
        // Convert the input to uppercase
        console.log(name + " " + value);
        //let formattedValue = value.replace("/e/gi", '');
        //let formattedValue = value.replace(/e/gi, '');
        let formattedValue = value.replace(/[^0-9]/g, '');
        //let formattedValue = value.replace(/\D/g, '');
        // Check for udyamNumber format (22 characters, alphanumeric)
        const cinRegex = /^\d{17}$/;
        if (cinRegex.test(formattedValue)) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
            ESICStatus: "Filled"
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            ESICError: '',
          }));
        } else if (formattedValue.length === 17) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            ESICError: 'Invalid ESIC Number format. Please enter a valid EPF Number. Ex: 00112271234455662'
          }));
        }
        else if (formattedValue.length > 17) {
          formattedValue = formattedValue.slice(0, 17); // Trim to 17 digits
        } else if (!/^\d+$/.test(value)) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            ESICError: 'Only numeric characters are allowed.'
          }));
        }
        else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            ESICError: 'Not as ESIC Number Format. Ex: 00112271234455662'
          }));
        }
      }

      else if (name === "TANNumber") {
        // Convert the input to uppercase
        console.log(name + " " + value);
        const formattedValue = value.toUpperCase();
        // Check for TANNumber format (4 alphabets, 5 numbers, 1 alphabet)
        const panRegex = /[A-Z]{4}\d{5}[A-Z]$/;
        if (panRegex.test(formattedValue)) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
            TANStatus: "Filled"
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            TANError: '',
          }));
        }

        /*else if (formattedValue.length === 0) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            PANError: '',
          }));
        }*/

        else if (formattedValue.length === 10) {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            TANError: 'Invalid TAN format. Please enter a valid PAN. Ex: ABCT01234D'
          }));
        }
        else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            TANError: 'Not as TAN Format'
          }));
        }
      }

      else if (type === "date") {
        console.log("Entered Date", value);
        const formattedDate = formatDateToDMY(value);
        console.log(formattedDate);
        //console.log(name);
        //setKYVData({ ...kyvData, [name]: formattedDate });
        setKYVData((prevState) => ({
          ...prevState,
          [name]: value,//instead of value we can put formattedDate but it does not accept the formattedDate format. But this formattedDate can be used for Verification Purpose
        }));
      }

      else if (type === "checkbox") {
        if ((name === 'isCINNotApplicableChecked' || name === 'isCINNotRegisteredChecked') && checked) {
          if ((name === 'isCINNotApplicableChecked') && kyvData.isCINNotApplicableChecked === false) {
            if ((kyvData.isCINNotApplicableChecked === true && kyvData.isCINNotRegisteredChecked === false) || (kyvData.isCINNotApplicableChecked === false && kyvData.isCINNotRegisteredChecked === true)) {
              console.log("Entered IF If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Not Applicable and Not Registered"
              }));
              console.log("CIN Status is: ", kyvData.CINStatus);
            }

            else {
              console.log("Entered IF If->else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Not Applicable"
              }));
              console.log(kyvData.CINStatus);
            }
          }

          else if ((name === 'isCINNotRegisteredChecked') && kyvData.isCINNotRegisteredChecked === false) {
            if ((kyvData.isCINNotApplicableChecked === true && kyvData.isCINNotRegisteredChecked === false) || (kyvData.isCINNotApplicableChecked === false && kyvData.isCINNotRegisteredChecked === true)) {
              console.log("Entered IF else If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Not Applicable and Not Registered"
              }));
              console.log(kyvData.CINStatus);
            }
            else {
              console.log("Entered IF Else if-> else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Not Registered"
              }));
              console.log(kyvData.CINStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            CINError: '',
          }));

        }

        else if ((name === 'isCINNotApplicableChecked' || name === 'isCINNotRegisteredChecked') && checked === false) {
          if ((name === 'isCINNotApplicableChecked') && kyvData.isCINNotApplicableChecked === true) {
            if ((kyvData.isCINNotApplicableChecked === true && kyvData.isCINNotRegisteredChecked === true) || (kyvData.isCINNotApplicableChecked === false && kyvData.isCINNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Not Registered"
              }));
              console.log(kyvData.CINStatus);
            }

            else {
              console.log("Entered ELSE IF If->else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Filled"
              }));
              console.log(kyvData.CINStatus);
            }
          }

          else if ((name === 'isCINNotRegisteredChecked') && kyvData.isCINNotRegisteredChecked === true) {
            if ((kyvData.isCINNotApplicableChecked === true && kyvData.isCINNotRegisteredChecked === true) || (kyvData.isCINNotApplicableChecked === false && kyvData.isCINNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF else If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Not Applicable"
              }));
              console.log(kyvData.CINStatus);
            }
            else {
              console.log("Entered ELSE IF Else if-> else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                CINNumber: '',
                CINStatus: "Filled"
              }));
              console.log(kyvData.CINStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            CINError: '',
          }));

        }

        else if ((name === 'isGSTNotApplicableChecked' || name === 'isGSTNotRegisteredChecked') && checked) {
          if ((name === 'isGSTNotApplicableChecked') && kyvData.isGSTNotApplicableChecked === false) {
            if ((kyvData.isGSTNotApplicableChecked === true && kyvData.isGSTNotRegisteredChecked === false) || (kyvData.isGSTNotApplicableChecked === false && kyvData.isGSTNotRegisteredChecked === true)) {
              console.log("Entered IF If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Not Applicable and Not Registered"
              }));
              console.log(kyvData.GSTStatus);
            }

            else {
              console.log("Entered IF If->else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Not Applicable"
              }));
              console.log(kyvData.GSTStatus);
            }
          }

          else if ((name === 'isGSTNotRegisteredChecked') && kyvData.isGSTNotRegisteredChecked === false) {
            if ((kyvData.isGSTNotApplicableChecked === true && kyvData.isGSTNotRegisteredChecked === false) || (kyvData.isGSTNotApplicableChecked === false && kyvData.isGSTNotRegisteredChecked === true)) {
              console.log("Entered IF else If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Not Applicable and Not Registered"
              }));
              console.log(kyvData.GSTStatus);
            }
            else {
              console.log("Entered IF Else if-> else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Not Registered"
              }));
              console.log(kyvData.GSTStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: '',
          }));

        }

        else if ((name === 'isGSTNotApplicableChecked' || name === 'isGSTNotRegisteredChecked') && checked === false) {
          if ((name === 'isGSTNotApplicableChecked') && kyvData.isGSTNotApplicableChecked === true) {
            if ((kyvData.isGSTNotApplicableChecked === true && kyvData.isGSTNotRegisteredChecked === true) || (kyvData.isGSTNotApplicableChecked === false && kyvData.isCINNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Not Registered"
              }));
              console.log(kyvData.CINStatus);
            }

            else {
              console.log("Entered ELSE IF If->else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Filled"
              }));
              console.log(kyvData.CINStatus);
            }
          }

          else if ((name === 'isGSTNotRegisteredChecked') && kyvData.isGSTNotRegisteredChecked === true) {
            if ((kyvData.isGSTNotApplicableChecked === true && kyvData.isGSTNotRegisteredChecked === true) || (kyvData.isGSTNotApplicableChecked === false && kyvData.isGSTNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF else If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Not Applicable"
              }));
              console.log(kyvData.CINStatus);
            }
            else {
              console.log("Entered ELSE IF Else if-> else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                GSTNumber: '',
                GSTStatus: "Filled"
              }));
              console.log(kyvData.GSTStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            GSTError: '',
          }));

        }

        else if ((name === 'isEPFNotApplicableChecked' || name === 'isEPFNotRegisteredChecked') && checked) {
          if ((name === 'isEPFNotApplicableChecked') && kyvData.isEPFNotApplicableChecked === false) {
            if ((kyvData.isEPFNotApplicableChecked === true && kyvData.isEPFNotRegisteredChecked === false) || (kyvData.isEPFNotApplicableChecked === false && kyvData.isEPFNotRegisteredChecked === true)) {
              console.log("Entered IF If->if EPF both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Not Applicable and Not Registered"
              }));
              console.log(kyvData.EPFStatus);
            }

            else {
              console.log("Entered IF If->else EPF");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Not Applicable"
              }));
              console.log(kyvData.EPFStatus);
            }
          }

          else if ((name === 'isEPFNotRegisteredChecked') && kyvData.isEPFNotRegisteredChecked === false) {
            if ((kyvData.isEPFNotApplicableChecked === true && kyvData.isEPFNotRegisteredChecked === false) || (kyvData.isEPFNotApplicableChecked === false && kyvData.isEPFNotRegisteredChecked === true)) {
              console.log("Entered IF else If->if EPF both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Not Applicable and Not Registered"
              }));
              console.log(kyvData.EPFStatus);
            }
            else {
              console.log("Entered IF Else if-> else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Not Registered"
              }));
              console.log(kyvData.EPFStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            EPFError: '',
          }));

        }

        else if ((name === 'isEPFNotApplicableChecked' || name === 'isEPFNotRegisteredChecked') && checked === false) {
          if ((name === 'isEPFNotApplicableChecked') && kyvData.isEPFNotApplicableChecked === true) {
            if ((kyvData.isEPFNotApplicableChecked === true && kyvData.isEPFNotRegisteredChecked === true) || (kyvData.isEPFNotApplicableChecked === false && kyvData.isEPFNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF If->if EPF both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Not Registered"
              }));
              console.log(kyvData.EPFStatus);
            }

            else {
              console.log("Entered ELSE IF If->else EPF");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Filled"
              }));
              console.log(kyvData.EPFStatus);
            }
          }

          else if ((name === 'isEPFNotRegisteredChecked') && kyvData.isEPFNotRegisteredChecked === true) {
            if ((kyvData.isEPFNotApplicableChecked === true && kyvData.isEPFNotRegisteredChecked === true) || (kyvData.isEPFNotApplicableChecked === false && kyvData.isEPFNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF else If->if CIN both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Not Applicable"
              }));
              console.log(kyvData.EPFStatus);
            }
            else {
              console.log("Entered ELSE IF Else if-> else CIN");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                EPFNumber: '',
                EPFStatus: "Filled"
              }));
              console.log(kyvData.EPFStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            EPFError: '',
          }));

        }

        else if ((name === 'isESICNotApplicableChecked' || name === 'isESICNotRegisteredChecked') && checked) {
          if ((name === 'isESICNotApplicableChecked') && kyvData.isESICNotApplicableChecked === false) {
            if ((kyvData.isESICNotApplicableChecked === true && kyvData.isESICNotRegisteredChecked === false) || (kyvData.isESICNotApplicableChecked === false && kyvData.isESICNotRegisteredChecked === true)) {
              console.log("Entered IF If->if ESIC both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Not Applicable and Not Registered"
              }));
              console.log("ESIC Status is: ", kyvData.ESICStatus);
            }

            else {
              console.log("Entered IF If->else ESIC");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Not Applicable"
              }));
              console.log(kyvData.ESICStatus);
            }
          }

          else if ((name === 'isESICNotRegisteredChecked') && kyvData.isESICNotRegisteredChecked === false) {
            if ((kyvData.isESICNotApplicableChecked === true && kyvData.isESICNotRegisteredChecked === false) || (kyvData.isESICNotApplicableChecked === false && kyvData.isESICNotRegisteredChecked === true)) {
              console.log("Entered IF else If->if ESIC both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Not Applicable and Not Registered"
              }));
              console.log(kyvData.ESICStatus);
            }
            else {
              console.log("Entered IF Else if-> else ESIC");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Not Registered"
              }));
              console.log(kyvData.ESICStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            ESICError: '',
          }));

        }

        else if ((name === 'isESICNotApplicableChecked' || name === 'isESICNotRegisteredChecked') && checked === false) {
          if ((name === 'isESICNotApplicableChecked') && kyvData.isESICNotApplicableChecked === true) {
            if ((kyvData.isESICNotApplicableChecked === true && kyvData.isESICNotRegisteredChecked === true) || (kyvData.isESICNotApplicableChecked === false && kyvData.isESICNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF If->if ESIC both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Not Registered"
              }));
              console.log(kyvData.ESICStatus);
            }

            else {
              console.log("Entered ELSE IF If->else ESIC");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Filled"
              }));
              console.log(kyvData.ESICStatus);
            }
          }

          else if ((name === 'isESICNotRegisteredChecked') && kyvData.isESICNotRegisteredChecked === true) {
            if ((kyvData.isESICNotApplicableChecked === true && kyvData.isESICNotRegisteredChecked === true) || (kyvData.isESICNotApplicableChecked === false && kyvData.isESICNotRegisteredChecked === false)) {
              console.log("Entered ELSE IF else If->if ESIC both");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Not Applicable"
              }));
              console.log(kyvData.ESICStatus);
            }
            else {
              console.log("Entered ELSE IF Else if-> else ESIC");
              setKYVData((prevState) => ({
                ...prevState,
                [name]: checked,
                ESICNumber: '',
                ESICStatus: "Filled"
              }));
              console.log(kyvData.ESICStatus);
            }
          }

          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            ESICError: '',
          }));

        }

        else if (name === 'isTANNotApplicableChecked' && checked) {
          // Handle EPFNumber update here
          setKYVData((prevState) => ({
            ...prevState,
            [name]: checked,
            TANNumber: '',
            TANMobileNumber: '',
            TANStatus: 'Not Applicable',
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            TANError: '',
          }));
        }

        else if (name === 'isTANNotApplicableChecked' && checked === false) {
          // Handle EPFNumber update here
          setKYVData((prevState) => ({
            ...prevState,
            [name]: checked,
            TANStatus: 'Filled',
          }));
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            TANError: '',
          }));
        }

        else {
          setKYVData((prevState) => ({
            ...prevState,
            [name]: checked, // Toggle the checkbox state
          }));
        }
      }

      else if (type === "radio") {
        console.log(name, value, "radio");
        setKYVData((prevState) => ({
          ...prevState,
          [name]: checked ? value : '',
        }));
      }

      else {
        console.log(name, value);
        setKYVData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }

    }

    else {
      setKYVData((prevState) => ({
        ...prevState,
        financeManagerPhoneNumber: event,
      }));
    }
  };

  const formatDateForDisplay = (date) => {
    const [yyyy, mm, dd] = date.split('-');
    return `${dd}-${mm}-${yyyy}`;
  };

  const fetchBankDetails = async (ifscCode) => {
    try {
      const response = await fetch(`https://bank-apis.justinclicks.com/API/V1/IFSC/${ifscCode}/`, {
        method: 'GET',
      });
      console.log("Fetching IFSC API");
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        // Assuming the API response provides the necessary details
        // Modify bankAccountData based on the API response
        setBankAccountData((prevState) => ({
          ...prevState,
          // Modify the details based on the API response
          bankName: data.BANK,
          branchName: data.BRANCH,
          bankAddressRegion: data.DISTRICT,
          bankAddressStreet: data.CENTRE,
          bankAddressCity: data.CITY,
          // Add other fields as needed
        }));
      } else {
        // Handle API error
        console.error("Error fetching bank details from API");
      }
    } catch (error) {
      console.error("Error fetching bank details:", error);
    }
  };

  const [bankAccountNumberError, setBankAccountNumberError] = useState('');

  const handleBankAccountInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "swiftCode") {
      setBankAccountData({ ...bankAccountData, swiftCode: value });
      if (value.length === 11) {
        setBankAccountData({ ...bankAccountData, swiftCode: value });
        
          fetchBankDetails(value);
        }
        else {
          setBankAccountData((prevState) => ({
            ...prevState,
            // Modify the details based on the API response
            bankName: '',
            branchName: '',
            bankAddressRegion: '',
            bankAddressStreet: '',
            bankAddressCity: '',
            // Add other fields as needed
          }));
        }
    }
    else if (name === "bankAccountNumber") {
      const numericValue = parseInt(value);
      if (!isNaN(numericValue) && value.length >= 12 && value.length <= 18) {
        setBankAccountData((prevState) => ({
          ...prevState,
          [name]: numericValue,
        }));
        //console.log("Bank Acc Data: "+bankAccountData.bankAccountNumber);
        setBankAccountNumberError('');
      }

      else if (!isNaN(numericValue) && value.length > 18) {
        setBankAccountNumberError('');
      }

      else {
        setBankAccountData((prevState) => ({
          ...prevState,
          [name]: numericValue,
        }));
        setBankAccountNumberError('Account number must be between 12 and 18 digits.');
      }
    }
    else {
      setBankAccountData({ ...bankAccountData, [name]: value });
    }
  };

  /* const handleCountryOfTaxResidencyChange = (selectedOption) => {
     setSelectedCountry(selectedOption);
     console.log("Selected Option is: ", selectedOption);
     setKYVData({ ...kyvData, countryOfTaxResidency: selectedOption.value, countryOfTaxResidencyLabel: selectedOption.label });
     console.log("Selected Option is Label is : ", selectedOption.label);
     //console.log("Selected Option value is: ", kyvData.countryOfTaxResidency);
   };*/

  const handleOrganisationTypeChange = (event) => {
    const { name, value } = event.target;
    setIntroductionData({ ...introductionData, [name]: value });
  };

  const handleVendorTypeChange = (event) => {
    const { name, value } = event.target;
    setIntroductionData({ ...introductionData, [name]: value });
  };

  const handleNext = () => {
    /* Change to next page if certain conditions met only */

    if (currentStep === 1) {
      // Check if the required fields in step 1 are filled out
      if (true) {
        // Proceed to the next step
        setCurrentStep(currentStep + 1);
        steps[currentStep - 1].isCompleted = true;
        setCompletedSteps([...completedSteps, currentStep]); // Mark step as completed
        markStepAsCompleted(currentStep);
      } else {
        // Stay on the current step or show an error message
        // You can show an error message to indicate what's missing
        // or simply prevent progression until conditions are met
      }
    }
    else if (currentStep === 2 || currentStep === 3) {
      // Check conditions for step 2, and so on...
      if (true) {
        setCurrentStep(currentStep + 1);
        steps[currentStep - 1].isCompleted = true;
        setCompletedSteps([...completedSteps, currentStep]); // Mark step as completed
        markStepAsCompleted(currentStep);
      }

      else {
        console.log("Cannot redirect to next Page");
      }
    }
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
    //removeStepFromCompleted(currentStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Send form data to the server or perform any desired actions
    console.log(data);
    console.log(introductionData);
    console.log(kyvData);
    console.log(bankAccountData);
  };

  const handleReset = async () => {
    if (currentStep === 1) {
      setTimeout(() => {
        // Set the state to indicate that data is saved successfully
        setIsIntroductionDataResetted(true);

        // After 5 seconds (adjust the delay as needed), reset the state to hide the message
        setTimeout(() => {
          setIsIntroductionDataResetted(false);
        }, 5000);
      }, 1000);

      const mandatoryFields = ['businessRegistration', 'vendorPrimaryEmail', 'vendorPrimaryMobile', 'organisationName', 'vendorPrimaryMobileCountryCode'];

      // Save the values you want to preserve
      const preservedValues = Object.fromEntries(
        Object.entries(introductionData)
          .filter(([fieldName]) => mandatoryFields.includes(fieldName))
      );
      const business = introductionData.businessRegistration;
      const email = introductionData.vendorPrimaryEmail;
      const mobile = parseInt(introductionData.vendorPrimaryMobile);
      const org = introductionData.organisationName;
      const mobileCode = introductionData.vendorPrimaryMobileCountryCode;
      console.log("Before Data: ", introductionData);
      setIntroductionData((prevData) => ({
        ...prevData,
        ...initialIntroductionData,
        ...preservedValues,
        businessRegistration: business,
        vendorPrimaryEmail: email,
        vendorPrimaryMobile: mobile,
        organisationName: org,
        vendorPrimaryMobileCountryCode: mobileCode,
      }));

      const updatedFields = {
        ...existingData,
        // Start of Introduction Data
        BUTXT: introductionData.businessRegistration,
        VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail,
        TELEFTO: introductionData.vendorPrimaryMobileCountryCode,
        VENDOR_P_MOBNO: parseInt(introductionData.vendorPrimaryMobile),
        VENDOR_TYPE: initialIntroductionData.vendorType,
        VENDOR_NO: vendorNumber,
        ORG_TYPE: initialIntroductionData.organisationType,
        VENDOR_COMP_NAME: introductionData.organisationName,
        NAMEOFCONT_SAL: initialIntroductionData.contactPersonSalutation,
        BU_SORT1_TXT: initialIntroductionData.contactPersonFirstName,
        BU_SORT2_TXT: initialIntroductionData.contactPersonLastName,
        CONT_PERSON_DES: initialIntroductionData.contactPersonDesignation,
        POST_CODE1: parseInt(initialIntroductionData.registeredOfficeAddressPincode),
        CITY1: initialIntroductionData.registeredOfficeAddressCity,
        DISTRICT_REG: initialIntroductionData.district,
        STATE: initialIntroductionData.registeredOfficeAddressState, // In SAP DB State is saved as Region
        BEZEI: initialIntroductionData.registeredOfficeAddressStateDescription,
        COUNTRY: initialIntroductionData.registeredOfficeAddressCountry,
        LANDX: initialIntroductionData.registeredOfficeAddressCountryDescription,
        BUILDING_BLOCK: initialIntroductionData.registeredOfficeAddressBuilding,
        STREET: initialIntroductionData.registeredOfficeAddressStreet,
        BRIEF_INTRO: initialIntroductionData.briefIntroduction,
        NAMEOF_FIN_MANGR: initialIntroductionData.financeManagerName,
        CNTRY_FIN_MANGR: initialIntroductionData.financeManagerPhoneNumberCountryCode,
        PHNOOF_FIN_MANGR: parseInt(initialIntroductionData.financeManagerPhoneNumber),
        EMAILOF_FIN_MANGR: initialIntroductionData.financeManagerEmail,
        // End of Introduction Data
      };
      setSelectedAPICountryValue('');
      setSelectedAPIStateValue('');

      const updatedData = { ...existingData, ...updatedFields };

      //const apiData = {  VENDOR_NO: vendorNumber, VENDOR_P_EMAIL: introductionData.vendorPrimaryEmail, VENDOR_P_MOBNO: introductionData.vendorPrimaryMobile };
      const resetApiUrl = `https://prilhanaqms01.patilgroup.com:44301/sap/bc/rest/zvendor/Vendor_PRIPL?sap-client=500&AUTO_USER=${vendorNo}`;
      console.log("Updated Data is: ", updatedData);

      // Simulate an API call to reset data
      await fetch(resetApiUrl, {
        method: 'PUT',
        headers: {
          'Authorization': 'Basic ' + base64Credentials,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
      console.log("After Data: ", introductionData);


    } else if (currentStep === 2) {

      setTimeout(() => {
        // Set the state to indicate that data is saved successfully
        setIsKYVDataResetted(true);
        setErrorMessages(initialKYVErrorMessages);
        // After 5 seconds (adjust the delay as needed), reset the state to hide the message
        setTimeout(() => {
          setIsKYVDataResetted(false);
        }, 5000);
      }, 1000);
      //setSelectedCountry(null)
      setKYVData(initialKYVData);
      setSelectedAPICountryOfTaxResidencyValue('');
      //setSelectedCountry(null);
      const updatedData = {
        ...existingData,
        LANDX1: '',

      }
      const resetApiUrl = `https://prilhanaqms01.patilgroup.com:44301/sap/bc/rest/zvendor/Vendor_PRIPL?sap-client=500&AUTO_USER=${vendorNo}`;

      // Simulate an API call to reset data
      await fetch(resetApiUrl, {
        method: 'PUT',
        headers: {
          'Authorization': 'Basic ' + base64Credentials,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      // Conditionally update specific fields and set preserved values for others
      console.log('Fields reset successfully in KYV Screen');
      console.log("Existing Data is: ", existingData);

    } else if (currentStep === 3) {

      setTimeout(() => {
        // Set the state to indicate that data is saved successfully
        setIsBankDataResetted(true);

        // After 5 seconds (adjust the delay as needed), reset the state to hide the message
        setTimeout(() => {
          setIsBankDataResetted(false);
        }, 5000);
      }, 1000);

      setBankAccountData(initialbankAccountData);
      const updatedData = {
        ...existingData,
        BANKL: initialbankAccountData.swiftCode,
        BANKA: bankAccountData.bankName,
        KOINH: bankAccountData.bankAccountHolderName,
        BANKN: bankAccountData.bankAccountNumber,
        BANK_BRANCH: bankAccountData.branchName,
        BANK_ADDRESS: bankAccountData.bankAddress,
        STRAS: bankAccountData.bankAddressStreet,
        PROVZ: bankAccountData.bankAddressRegion,
        ORT01: bankAccountData.bankAddressCity,

      }
      const resetApiUrl = `https://prilhanaqms01.patilgroup.com:44301/sap/bc/rest/zvendor/Vendor_PRIPL?sap-client=200&AUTO_USER=${vendorNo}`;

      // Simulate an API call to reset data
      await fetch(resetApiUrl, {
        method: 'PUT',
        headers: {
          'Authorization': 'Basic ' + base64Credentials,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
      console.log("Existing Data is : ", existingData);
      console.log('Fields reset successfully in Bank Screen');
    }

    // Reset step completion status
    steps.forEach((step) => {
      step.isCompleted = false;
    });
  };


  /*useEffect(() => {
    fetch(
      'https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code'
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      });
  }, []);*/

  const renderUdyamMobileOrEmail = () => {
    if (kyvData.otpRadioOption === 'mobileOTPForUdyam') {
      return (
        <div className='d-flex flex-column'>
          <div className='d-flex flex-row'>
            <div className='col-5 col-md-4 check-box-styling'>
              <label htmlFor='udyamPhoneNumber'>
                Enter Mobile Number registered with Udyam
              </label>
            </div>

            <div className='col-7 col-md-8'>
              <input
                type="number"
                id="udyamPhoneNumber"
                className='form-control select-input-registration'
                name="udyamPhoneNumber"
                value={kyvData.udyamPhoneNumber}
                onChange={handleKYVInputChange}
                placeholder="UDYAM Phone Number"
              />
            </div>
          </div>

          <div className='d-flex flex-row mt-2'>
            <div className='col-5 col-md-4 check-box-styling'>
              <label htmlFor='udyamPhoneNumberOTP'>
                Enter Mobile Number OTP
              </label>
            </div>

            <div className='col-7 col-md-8'>
              <input
                type="number"
                id="udyamPhoneNumberOTP"
                className='form-control select-input-registration'
                name="udyamPhoneNumberOTP"
                value={kyvData.udyamPhoneNumberOTP}
                onChange={handleKYVInputChange}
                placeholder="Phone Number OTP"
              />
            </div>
          </div>
        </div>
      )
    }

    else if (kyvData.otpRadioOption === 'emailOTPForUdyam') {
      return (
        <div className='d-flex flex-column'>
          <div className='d-flex flex-row'>
            <div className='col-5 col-md-4 check-box-styling'>
              <label htmlFor='udyamEmail'>
                Enter Email registered with Udyam
              </label>
            </div>

            <div className='col-7 col-md-8'>
              <input
                type="email"
                id="udyamEmail"
                className='form-control select-input-registration'
                name="udyamEmail"
                value={kyvData.udyamEmail}
                onChange={handleKYVInputChange}
                placeholder="UDYAM Email"
              />
            </div>
          </div>
          <div className='d-flex flex-row mt-2'>
            <div className='col-5 col-md-4 check-box-styling'>
              <label htmlFor='udyamEmailOTP'>
                Enter Email OTP
              </label>
            </div>

            <div className='col-7 col-md-8'>
              <input
                type="number"
                id="udyamEmailOTP"
                className='form-control select-input-registration'
                name="udyamEmailOTP"
                value={kyvData.udyamEmailOTP}
                onChange={handleKYVInputChange}
                placeholder="Email OTP"
              />
            </div>
          </div>
        </div>
      )
    }
  }

  const renderMSMEMobileOrEmail = () => {
    if (kyvData.otpRadioOption === 'mobileOTPForMSME') {
      return (
        <div className='d-flex flex-column'>
          <div className='d-flex flex-row'>
            <div className='col-5 col-md-4 check-box-styling'>
              <label htmlFor='msmePhoneNumber'>
                Enter Mobile Number registered with MSME
              </label>
            </div>

            <div className='col-7 col-md-8'>
              <input
                type="number"
                id="msmePhoneNumber"
                className='form-control select-input-registration'
                name="msmePhoneNumber"
                value={kyvData.msmePhoneNumber}
                onChange={handleKYVInputChange}
                placeholder="MSME Phone Number"
              />
            </div>
          </div>
          <div className='d-flex flex-row mt-2'>
            <div className='col-5 col-md-4 check-box-styling'>
              <label htmlFor='msmePhoneNumberOTP'>
                Enter Mobile Number OTP
              </label>
            </div>

            <div className='col-7 col-md-8'>
              <input
                type="number"
                id="msmePhoneNumberOTP"
                className='form-control select-input-registration'
                name="msmePhoneNumberOTP"
                value={kyvData.msmePhoneNumberOTP}
                onChange={handleKYVInputChange}
                placeholder="Phone Number OTP"
              />
            </div>
          </div>
        </div>
      )
    }

    else if (kyvData.otpRadioOption === 'emailOTPForMSME') {
      return (
        <div>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-row'>
              <div className='col-5 col-md-4 check-box-styling'>
                <label htmlFor='msmeEmail'>
                  Enter Email registered with MSME
                </label>
              </div>

              <div className='col-7 col-md-8'>
                <input
                  type="text"
                  id="msmeEmail"
                  className='form-control select-input-registration'
                  name="msmeEmail"
                  value={kyvData.msmeEmail}
                  onChange={handleKYVInputChange}
                  placeholder="Enter Email"
                />
              </div>
            </div>
          </div>
          <div className='d-flex flex-row mt-2'>
            <div className='col-5 col-md-4 check-box-styling'>
              <label htmlFor='msmeEmailOTP'>
                Enter Email OTP
              </label>
            </div>

            <div className='col-7 col-md-8'>
              <input
                type="text"
                id="msmeEmailOTP"
                className='form-control select-input-registration'
                name="msmeEmailOTP"
                value={kyvData.msmeEmailOTP}
                onChange={handleKYVInputChange}
                placeholder="Email OTP"
              />
            </div>
          </div>
        </div>
      )
    }
  }

  const renderUdyamOrMSME = () => {
    if (kyvData.radioOption === 'UDYAM') {
      return (
        <div className='row'>
          <div className='col-5 col-md-2 form-label-styling-registration'>
            <label htmlFor='udyamNumber'>
              Udyam Number
            </label>
          </div>

          <div className='d- flex flex-column col-7 col-md-4 text-left-registration'>
            <div>
              {isEditMode ? (
                <>
                  <input
                    type="text"
                    className='form-control select-input-registration'
                    id="udyamNumber"
                    name="udyamNumber"
                    value={kyvData.udyamNumber}
                    onChange={handleKYVInputChange}
                    placeholder="UDYAM Number"
                    minLength={19}
                    maxLength={19}
                  />
                  {errorMessages.UDYAMError && <div className="error-message">{errorMessages.UDYAMError}</div>}
                </>) : (
                <span className='view-mode-stylings'>{kyvData.udyamNumber}</span>
              )}
            </div>

            <div className='mt-2'>
              <label className='check-box-styling'>
                <input
                  type="radio"
                  name="otpRadioOption"
                  id="mobileOTPForUdyam"
                  value="mobileOTPForUdyam"
                  checked={kyvData.otpRadioOption === 'mobileOTPForUdyam'}
                  onChange={handleKYVInputChange}
                />
                OTP on Mobile as registered with UDYAM
              </label>
            </div>

            <div className='mt-2'>
              <label className='check-box-styling'>
                <input
                  type="radio"
                  name="otpRadioOption"
                  id="emailOTPForUdyam"
                  value="emailOTPForUdyam"
                  checked={kyvData.otpRadioOption === 'emailOTPForUdyam'}
                  onChange={handleKYVInputChange}
                />
                OTP on Email as registered with UDYAM
              </label>
            </div>

          </div>

          <div className="col-12 col-md-6">
            {renderUdyamMobileOrEmail()}
          </div>

        </div>
      );
    }
    else if (kyvData.radioOption === 'MSME') {
      return (
        <div className='row'>
          <div className='col-5 col-md-2 form-label-styling-registration'>
            <label htmlFor='msmeNumber'>
              MSME Number
            </label>
          </div>

          <div className='d- flex flex-column col-7 col-md-4 text-left-registration'>
            <div>
              {isEditMode ? (
                <>
                  <input
                    type="text"
                    className='form-control select-input-registration'
                    id="msmeNumber"
                    name="msmeNumber"
                    value={kyvData.msmeNumber}
                    onChange={handleKYVInputChange}
                    placeholder="MSME Number"
                  />
                  {errorMessages.MSMEError && <div className="error-message">{errorMessages.MSMEError}</div>}
                </>) : (
                <span>{kyvData.msmeNumber}</span>
              )}
            </div>

            <div className='mt-2'>
              <label className='check-box-styling'>
                <input
                  type="radio"
                  name="otpRadioOption"
                  id="mobileOTPForMSME"
                  value="mobileOTPForMSME"
                  checked={kyvData.otpRadioOption === 'mobileOTPForMSME'}
                  onChange={handleKYVInputChange}
                />
                OTP on Mobile as registered with MSME
              </label>
            </div>

            <div className='mt-2'>
              <label className='check-box-styling'>
                <input
                  type="radio"
                  name="otpRadioOption"
                  id="emailOTPForMSME"
                  value="emailOTPForMSME"
                  checked={kyvData.otpRadioOption === 'emailOTPForMSME'}
                  onChange={handleKYVInputChange}
                />
                OTP on Email as registered with MSME
              </label>
            </div>

          </div>

          <div className="col-12 col-md-6">
            {renderMSMEMobileOrEmail()}
          </div>

        </div>
      );
    }
  }

  return (
    <div>
      {loading && (
        <div className='overlay'>
          <div className='spinner-container'>
            <div className="loading-spinner"></div>
            <span className='loading-message'>Loading...</span>
          </div>
        </div>
      )}
      {/*  */}
      {/* {fetching && (
        <div className='overlay'>
          <div className='spinner-container'>
            <div className="loading-spinner"></div>
            <span className='loading-message'>Fetching Data, Please wait</span>
          </div>
        </div>
      )} */}

      <div className="container" style={{ padding: '0px', marginTop: '5px' }}>
        <div className='d-flex' style={{ margin: '0px' }}>
          <div id="backgroundImageContainer" style={{ width: '30%' }}>
            <div className="card card-header-registration-bg">
              <div className="d-flex flex-row justify-content-center text-center">
                <div className="card-header-registration">
                  <h4>Vendor Onboarding</h4>
                </div>
              </div>
            </div>
          </div>

          <div id="stepperContainer" style={{ width: '70%' }}>
            <Box>
              <Stepper activeStep={currentStep - 1} alternativeLabel>
                {steps.map((step) => (
                  <Step key={step.id} onClick={() => handleStepperClick(step.id, true)} className='stepper-number-click-registration'>
                    <StepLabel
                      icon={
                        <div
                          style={{
                            //backgroundColor: completedSteps.includes(step.id) ? 'green' : '#6495ED',
                            //backgroundColor: isEditMode || completedSteps.includes(step.id) ? 'green' : '#6495ED',
                            // backgroundColor: isEditMode && completedSteps.includes(step.id) ? 'green' : isEditMode ? '#6495ED' : 'green',
                            backgroundColor:
                              currentStep === step.id ? 'pink' : // Active step
                                // completedSteps.includes(step.id) ? 'green' : // Completed step
                                '#6495ED', // Default color for ongoing steps
                            borderRadius: '50%',
                            width: '36px',
                            height: '36px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <span style={{ color: 'white', fontSize: '15px', margin: '0px', padding: '0px' }}>{step.icon}</span>
                        </div>
                      }
                      style={{ margin: '0px', padding: '0px' }}
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>

        </div>

        {/*<div>
        {data ? (
          <div>
            <h1>API Data</h1>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>*/}

        <div className="card-body-registration">
          <form onSubmit={handleSubmit}>
            {currentStep === 1 && (

              <div className="table1-registration">
                <div className='row'>
                  {/* Business Registration */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="businessRegistration">
                      Which of our Business would you like to register with
                    </label>
                  </div>
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="businessRegistration"
                      name="businessRegistration"
                      value={introductionData.businessRegistration}
                      onChange={handleIntroductionInputChange}
                      maxLength="40"
                      disabled={true}
                      required
                    />) : (
                      <span className='text-left-registration view-mode-stylings'>{introductionData.businessRegistration}</span>
                    )}
                  </div>

                  {/* Vendor Type */}

                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="vendorType">
                      Vendor Type
                    </label>
                  </div>

                  <div className='col-7 col-md-4 custom-select-container text-left-registration'>
                    {/*<select id="vendorType" name="vendorType" className="form-control select-input-registration"
                    onChange={handleVendorTypeChange} value={introductionData.vendorType}
                  >
                    <option value="">Select Option</option>
                    <option value="registered">Registered</option>
                    <option value="urd">URD</option>
                    <option value="wc">WC</option>
                    <option value="endCustomer">End Customer</option>
                    <option value="govt">Govt</option>
          </select>*/}
                    <div className="custom-select-wrapper">
                      {isEditMode ? (<select
                        id="vendorType"
                        name="vendorType"
                        className="form-control select-input-registration"
                        onChange={handleVendorTypeChange}
                        value={introductionData.vendorType}
                      >
                        {/*{Object.keys(displayLabels).map((key,value) => (
                        <option key={key} value={value}>
                          {displayLabels[value]}
                        </option>
                      ))}*/}
                        <option value="">Select Option</option>
                        <option value="01">Registered</option>
                        <option value="02">ITC</option>
                        {/*<option value="02">URD</option>
                        <option value="03">WC</option>
                        <option value="04">End Customer</option>
                    <option value="05">Govt</option>*/}
                      </select>) : (
                        <span className='view-mode-stylings'>{displayLabels[introductionData.vendorType]}</span>
                      )}
                      {isEditMode ? (
                        <i className="fas fa-circle-chevron-down custom-caret"></i>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row margin-settings'>
                  {/* Name of the organisation */}
                  {/* Label of Name of the organisation */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="organisationName">
                      Name of your Organisation
                    </label>
                  </div>
                  {/* Select of Name of the organisation Type */}
                  <div className='col-3 col-md-1 custom-select-container text-left-registration'>
                    {/*<label htmlFor='organisationType'></label>*/}
                    <div className="custom-select-wrapper">
                      {isEditMode ? (<select
                        id="organisationType" name="organisationType"
                        className="form-control select-input-registration"
                        onChange={handleOrganisationTypeChange} value={introductionData.organisationType}
                      >
                        <option value="">Select</option>
                        <option value="Pvt.Ltd">Pvt Ltd</option>
                        {/*<option value="u">URD</option>
                      <option value="w">WC</option>
                      <option value="e">End Customer</option>
                    <option value="g">Govt</option>*/}
                      </select>) : (
                        <span className='view-mode-stylings'>{introductionData.organisationType}</span>
                      )}
                      {isEditMode ? (
                        <i className="fas fa-circle-chevron-down custom-caret"></i>
                      ) : (
                        <span></span>
                      )}
                      {/*<i className="fas fa-circle-chevron-down fa-caret-down custom-caret"></i>*/}

                      {/*<div style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px' }}>
        <FontAwesomeIcon icon={faCaretDown} />
        </div>*/}
                    </div>
                    {/* <div style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '10px',
                    pointerEvents: 'none',
                  }}>
                    <i className="fas fa-caret-down"></i>
                  </div>*/}
                  </div>
                  {/* Organisation Name */}
                  <div className='col-4 col-md-3 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="organisationName"
                      name="organisationName"
                      value={introductionData.organisationName}
                      onChange={handleIntroductionInputChange}
                      maxLength="30"
                      disabled={true}
                      required
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.organisationName}</span>
                    )}
                  </div>

                  {/* Vendor Primary Email Address */}
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    {/* Label of Vendor Primary Email Address */}
                    <label htmlFor="vendorPrimaryEmail">
                      Vendor Primary Email
                    </label>
                  </div>
                  {/* Vendor Primary Email Address Input */}
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="email"
                      className="form-control select-input-registration"
                      id="vendorPrimaryEmail"
                      name="vendorPrimaryEmail"
                      value={introductionData.vendorPrimaryEmail}
                      onChange={handleIntroductionInputChange}
                      maxLength="50"
                      disabled={true}
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.vendorPrimaryEmail}</span>
                    )}
                  </div>
                </div>

                <div className='row margin-settings'>
                  {/* Vendor Primary Mobile */}
                  {/* Label of Vendor Primary Mobile */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="vendorPrimaryMobileCountryCode">
                      Vendor Primary Mobile
                    </label>
                  </div>

                  {/*<PhoneInput
                    type="number"
                    className="phone-input variation-height"
                    id="vendorPrimaryMobile"
                    name="vendorPrimaryMobile"
                    placeholder="Default Mobile Number"
                    //country="in"
                    value={introductionData.vendorPrimaryMobile}
                    onChange={handleIntroductionInputChange}
                    disabled={true}
                  />*/}

                  {/*} <div className="col-1 text-left-registration">
                  {/* Label of Vendor Primary Mobile 
                  {isEditMode ? (<input
                    type="number"
                    id="vendorPrimaryMobileCountryCode"
                    className='form-control select-input-registration'
                    name="vendorPrimaryMobileCountryCode"
                    value={introductionData.vendorPrimaryMobileCountryCode}
                    onChange={handleSelectCountryCodeChange}
                    placeholder="Select"
                    minLength={10}
                    maxLength={10}
                    disabled={true}
                  />
                  )
                    : (
                      <span className='view-mode-stylings'>{introductionData.vendorPrimaryMobileCountryCode}</span>
                    )}
                </div>*/}

                  <div className='col-1 custom-select-container text-left-registration'>
                    {/*<input
                      type="text"
                      className="form-control select-input-registration"
                      id="registeredOfficeAddressCountry"
                      name="registeredOfficeAddressCountry"
                      value={introductionData.registeredOfficeAddressCountry}
                      onChange={handleIntroductionInputChange}
                      maxLength="10"
                  />*/}

                    {/* Country Code */}
                    <div className='custom-select-wrapper'>
                      {isEditMode ? (<select id="vendorPrimaryMobileCountryCode" onChange={handleSelectCountryCodeChange} value={selectedPrimaryVendorCountryCode}
                        className='form-control select-input-registration custom-select-monospace' disabled={true}>
                        <option value="">Select an option</option>
                        {[...distinctCountryCodeMap].map(([key, value], index) => (
                          <option key={index} value={`+${value}`}>
                            {`${key}`.padEnd(4, ' ')} - {`+${value}`}
                          </option>
                        ))}
                      </select>

                      ) : (
                        <span className='view-mode-stylings'> {introductionData.vendorPrimaryMobileCountryCode} </span>
                      )
                      }
                      {
                        isEditMode ? (

                          <i className="fas fa-circle-chevron-down custom-caret"></i>
                        ) : (
                          <span></span>
                        )
                      }
                    </div>
                  </div>

                  <div className="col-6 col-md-3 text-left-registration">
                    {/* Label of Vendor Primary Mobile */}
                    {isEditMode ? (<input
                      type="number"
                      id="vendorPrimaryMobile"
                      className='form-control select-input-registration'
                      name="vendorPrimaryMobile"
                      value={introductionData.vendorPrimaryMobile}
                      onChange={handleIntroductionInputChange}
                      placeholder="Vendor Phone Number"
                      minLength={10}
                      maxLength={10}
                      disabled={true}
                    />
                    )
                      : (
                        <span className='view-mode-stylings'>{introductionData.vendorPrimaryMobile}</span>
                      )}
                  </div>

                </div>

                <div className='d-flex'>
                  <div className='col-6 col-md-6'>
                    <div className='row margin-settings'>
                      {/* Contact Person Label */}
                      {/* Label of Vendor Primary Mobile */}
                      <div className='col-5 col-md-4 form-label-styling-registration'>
                        <label htmlFor="contactPersonSalutation">
                          Contact Person Name
                        </label>
                      </div>

                      <div className="col-1 col-md-2 custom-select-container text-left-registration">
                        <div className='custom-select-wrapper'>
                          {isEditMode ? (<select
                            id="contactPersonSalutation"
                            name="contactPersonSalutation"
                            className="form-control select-input-registration"
                            onChange={handleOrganisationTypeChange}
                            value={introductionData.contactPersonSalutation}
                          >
                            <option value=""></option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                            <option value="Miss.">Miss</option>
                            <option value="Dr.">Dr.</option>
                            <option value="Ms.">Ms.</option>
                          </select>
                          ) : (
                            <span className='view-mode-stylings'>{introductionData.contactPersonSalutation}</span>
                          )
                          }
                          {
                            isEditMode ? (
                              <i className="fas fa-circle-chevron-down custom-caret"></i>
                            ) : (
                              <span></span>
                            )
                          }
                        </div>
                      </div>

                      <div className='col-3 col-md-3 text-left-registration'>
                        {isEditMode ? (<input
                          type="text"
                          className="form-control select-input-registration"
                          id="contactPersonFirstName"
                          name="contactPersonFirstName"
                          placeholder='First Name'
                          value={introductionData.contactPersonFirstName}
                          onChange={handleIntroductionInputChange}
                          maxLength="50"
                        />
                        ) : (
                          <span className='view-mode-stylings'>{introductionData.contactPersonFirstName}{" "}{introductionData.contactPersonLastName}</span>
                        )
                        }
                      </div>

                      <div className='col-3 col-md-3 text-left-registration'>
                        {isEditMode ? (<input
                          type="text"
                          className="form-control select-input-registration"
                          id="contactPersonLastName"
                          name="contactPersonLastName"
                          placeholder='Last Name'
                          value={introductionData.contactPersonLastName}
                          onChange={handleIntroductionInputChange}
                          maxLength="50"
                        />
                        ) : (<span></span>)
                        }
                      </div>
                    </div>
                  </div>

                  <div className='col-6 col-md-6'>
                    <div className='row margin-settings'>
                      {/* Contact Person Designation Label */}
                      <div className='col-5 col-md-4 form-label-styling-registration'>
                        <label htmlFor="contactPersonDesignation">
                          Contact Person Designation
                        </label>
                      </div>

                      <div className='col-6 col-md-8 text-left-registration'>
                        {isEditMode ? (<input
                          type="text"
                          className="form-control select-input-registration"
                          id="contactPersonDesignation"
                          name="contactPersonDesignation"
                          value={introductionData.contactPersonDesignation}
                          onChange={handleIntroductionInputChange}
                          maxLength="50"
                          required
                        />
                        ) : (
                          <span className='view-mode-stylings'>{introductionData.contactPersonDesignation}</span>
                        )
                        }
                      </div>
                    </div>
                  </div>
                </div>

                {/*Address Details */}
                <div className="sub-headings margin-settings">
                  <h6>Address Details</h6>
                </div>
                <div className='row margin-settings'>
                  {/* Registered Office Address Building Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="registeredOfficeAddressBuilding">
                      Building
                    </label>
                  </div>

                  {/* Registered Office Address Building Input */}
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="registeredOfficeAddressBuilding"
                      name="registeredOfficeAddressBuilding"
                      value={introductionData.registeredOfficeAddressBuilding}
                      onChange={handleIntroductionInputChange}
                      maxLength="100"
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.registeredOfficeAddressBuilding}</span>
                    )}
                  </div>

                  {/* Registered Office Address Street Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="registeredOfficeAddressStreet">
                      Street
                    </label>
                  </div>

                  {/* Registered Office Address Street Input */}
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="registeredOfficeAddressStreet"
                      name="registeredOfficeAddressStreet"
                      value={introductionData.registeredOfficeAddressStreet}
                      onChange={handleIntroductionInputChange}
                      maxLength="60"
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.registeredOfficeAddressStreet}</span>
                    )}
                  </div>


                </div>

                <div className='row margin-settings'>

                  {/* Registered Office Address City Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="registeredOfficeAddressCity">
                      City
                    </label>
                  </div>

                  {/* Registered Office Address City Input */}
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="registeredOfficeAddressCity"
                      name="registeredOfficeAddressCity"
                      value={introductionData.registeredOfficeAddressCity}
                      onChange={handleIntroductionInputChange}
                      maxLength="40"
                    />
                    ) : (
                      <span className='view-mode-stylings'>{introductionData.registeredOfficeAddressCity}</span>
                    )
                    }
                  </div>

                  {/*District */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    {/* District Label */}
                    <label htmlFor="district">
                      District
                    </label>
                  </div>
                  {/*District Input */}
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="district"
                      name="district"
                      value={introductionData.district}
                      onChange={handleIntroductionInputChange}
                      maxLength="50"
                    />
                    ) : (
                      <span className='view-mode-stylings'>{introductionData.district}</span>
                    )
                    }
                  </div>
                </div>

                <div className='row margin-settings'>

                  {/* Registered Office Address Country Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="registeredOfficeAddressCountry">
                      Country
                    </label>
                  </div>

                  {/* Registered Office Address Country Input */}
                  <div className='col-7 col-md-4 custom-select-container text-left-registration'>
                    {/*<input
                      type="text"
                      className="form-control select-input-registration"
                      id="registeredOfficeAddressCountry"
                      name="registeredOfficeAddressCountry"
                      value={introductionData.registeredOfficeAddressCountry}
                      onChange={handleIntroductionInputChange}
                      maxLength="10"
                  />*/}
                    <div className='custom-select-wrapper'>
                      {isEditMode ? (<select id="registeredOfficeAddressCountry" onChange={handleSelectCountryChange} value={selectedAPICountryValue}
                        className='form-control select-input-registration'>
                        <option value="">Select an option</option>
                        {[...distinctCountryMap].map(([key, value], index) => (
                          <option key={index} value={value}>
                            {key}
                          </option>
                        ))}
                      </select>

                      ) : (
                        <span className='view-mode-stylings'> {introductionData.registeredOfficeAddressCountryDescription} </span>
                      )
                      }
                      {
                        isEditMode ? (

                          <i className="fas fa-circle-chevron-down custom-caret"></i>
                        ) : (
                          <span></span>
                        )
                      }
                    </div>
                  </div>
                  {/* Registered Office Address State Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="registeredOfficeAddressState">
                      State
                    </label>
                  </div>

                  {/* Registered Office Address State Input */}
                  {/*<input
                    type="text"
                    className="form-control select-input-registration"
                    id="registeredOfficeAddressState"
                    name="registeredOfficeAddressState"
                    value={introductionData.registeredOfficeAddressState}
                    onChange={handleIntroductionInputChange}
                    maxLength="3"
                  />*/}
                  <div className='col-7 col-md-4 custom-select-container text-left-registration'>
                    <div className='custom-select-wrapper'>
                      {isEditMode ? (<select id="registeredOfficeAddressState" onChange={handleSelectStateChange} value={selectedAPIStateValue}
                        className='form-control select-input-registration'
                      >
                        <option value="">Select an option</option>
                        {countryData.map((item, index) => (
                          <option key={item.RAPI_SBEZEI} value={index}
                          //selected={selectedAPIStateValue === index}
                          >
                            {item.RAPI_SBEZEI}
                          </option>
                        ))}
                      </select>)
                        : (
                          <span className='view-mode-stylings'>{introductionData.registeredOfficeAddressStateDescription}</span>
                        )
                      }
                      {isEditMode ? (
                        <i className="fas fa-circle-chevron-down custom-caret"></i>
                      ) : (
                        <span></span>
                      )
                      }
                    </div>
                  </div>

                </div>

                <div className='row margin-settings'>
                  {/* Registered Office Address Pincode Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="registeredOfficeAddressPincode">
                      Pincode
                    </label>
                  </div>

                  {/* Registered Office Address Pincode Input */}
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="registeredOfficeAddressPincode"
                      name="registeredOfficeAddressPincode"
                      value={introductionData.registeredOfficeAddressPincode}
                      onChange={handlePinCodeChange}
                      pattern="[0-9]*"
                      maxLength="6"
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.registeredOfficeAddressPincode}</span>
                    )
                    }
                  </div>

                </div>

                <div className='row margin-settings'>
                  {/* Brief Introduction Label */}
                  <div className='col-5 col-md-2 form-label-styling-registration'>
                    <label htmlFor="briefIntroduction">
                      Brief Introduction
                    </label>
                  </div>

                  {/* Brief Introduction Input */}

                  {/* <input
                    type="text"
                    className="form-control select-input-registration"
                    id="briefIntroduction"
                    name="briefIntroduction"
                    value={introductionData.briefIntroduction}
                    onChange={handleIntroductionInputChange}
                    maxLength="1000"
                  /> */}
                  <div className='col-7 col-md-10 text-left-registration'>
                    {isEditMode ? (<textarea
                      className="form-control select-input-registration"
                      id="briefIntroduction"
                      name="briefIntroduction"
                      value={introductionData.briefIntroduction}
                      onChange={handleIntroductionInputChange}
                      maxLength="1000"
                      rows={Math.ceil(introductionData.briefIntroduction.length / 140)} // Adjust the number of rows based on content
                      cols="140" // Adjust the number of visible columns as needed
                    />
                    ) : (
                      <span className='view-mode-stylings'>{introductionData.briefIntroduction}</span>
                    )}

                    {introductionData.briefIntroduction.length >= 1000 && (
                      <div className="tooltip">
                        <span className="tooltiptext">Maximum 1000 characters allowed.</span>
                      </div>
                    )}
                  </div>
                </div>

                {/*Finance Details */}
                <div className="sub-headings margin-settings">
                  <h6>Finance Manager Details</h6>
                </div>

                <div className="row margin-settings">
                  {/* Finance Manager Name */}
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="financeManagerName">
                      Name
                    </label>
                  </div>
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="financeManagerName"
                      name="financeManagerName"
                      placeholder="Enter Finance Manager Name"
                      value={introductionData.financeManagerName}
                      onChange={handleIntroductionInputChange}
                      maxLength="40"
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.financeManagerName}</span>
                    )}
                  </div>

                  {/*Finance Manager Mobile Number */}
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="financeManagerPhoneNumberCountryCode">
                      Mobile Number
                    </label>
                  </div>
                  {/*<PhoneInput
                    type="number"
                    className="phone-input"
                    id="financeManagerPhoneNumber"
                    name="financeManagerPhoneNumber"
                    placeholder="Enter Mobile Number"
                    //country="in"
                    value={introductionData.financeManagerPhoneNumber}
                    onChange={handleIntroductionPhoneInputChange}
                    searchCountry
                  />*/}

                  <div className='col-1 custom-select-container text-left-registration'>
                    {/*<input
                      type="text"
                      className="form-control select-input-registration"
                      id="registeredOfficeAddressCountry"
                      name="registeredOfficeAddressCountry"
                      value={introductionData.registeredOfficeAddressCountry}
                      onChange={handleIntroductionInputChange}
                      maxLength="10"
                  />*/}
                    <div className='custom-select-wrapper'>
                      {isEditMode ? (<select id="financeManagerPhoneNumberCountryCode" name="financeManagerPhoneNumberCountryCode" onChange={handleSelectCountryCodeChange} value={selectedManagerPhoneCountryCode}
                        className='form-control select-input-registration custom-select-monospace'>
                        <option value="">Select an option</option>
                        {[...distinctCountryCodeMap].map(([key, value], index) => (
                          <option key={index} value={`+${value}`}>
                            {`${key}`.padEnd(4, ' ')} - {`+${value}`}
                          </option>
                        ))}
                      </select>

                      ) : (
                        <span className='view-mode-stylings'> {introductionData.financeManagerPhoneNumberCountryCode} </span>
                      )
                      }
                      {
                        isEditMode ? (

                          <i className="fas fa-circle-chevron-down custom-caret"></i>
                        ) : (
                          <span></span>
                        )
                      }
                    </div>
                  </div>


                  <div className="col-6 col-md-3 text-left-registration">
                    {isEditMode ? (
                      <input
                        type="tel"
                        id="financeManagerPhoneNumber"
                        className='form-control select-input-registration'
                        name="financeManagerPhoneNumber"
                        value={introductionData.financeManagerPhoneNumber}
                        onChange={handlePhoneInputChange}
                        placeholder="Manager Phone Number"
                        minLength={10}
                        maxLength={10}
                      />
                    ) : (
                      <span className='view-mode-stylings'>{introductionData.financeManagerPhoneNumber}</span>
                    )
                    }
                  </div>

                </div>

                <div className="row margin-settings">
                  {/* Finance Manager Email */}
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="financeManagerEmail">
                      E-mail
                    </label>
                  </div>
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="email"
                      className={`form-control select-input-registration ${!isValidEmail ? 'is-invalid' : ''
                        }`}
                      id="financeManagerEmail"
                      name="financeManagerEmail"
                      placeholder="Enter Finance Manager Email"
                      value={introductionData.financeManagerEmail}
                      onChange={handleIntroductionInputChange}
                      onBlur={handleEmailBlur}
                      maxLength="50"
                      required
                    />) : (
                      <span className='view-mode-stylings'>{introductionData.financeManagerEmail}</span>
                    )}
                    {!isValidEmail && (
                      <div className="invalid-feedback">Invalid email format (should contain '@')</div>
                    )}
                  </div>
                </div>

              </div>

            )}

            {currentStep === 2 && (
              <div className="table1-registration">
                {/* Country Of Tax Residency */}
                <div className="row">
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    {/* Country Of Tax Residency Label */}
                    <label htmlFor="countryOfTaxResidency">
                      Country of Tax Residency
                    </label>
                  </div>
                  {/* Country Of Tax Residency Input */}
                  {/*<div className='col-7 col-md-4 text-left-registration'>
                {isEditMode ? (<Select
                    placeholder="Select Country"
                    className='select-input-registration'
                    options={countries}
                    value={selectedCountry}
                    onChange={handleCountryOfTaxResidencyChange}
                  />) : (
                    <span>{kyvData.countryOfTaxResidency}</span>
                  )}
                </div>*/}

                  <div className='col-7 col-md-4 custom-select-container text-left-registration'>
                    {/*<input
                      type="text"
                      className="form-control select-input-registration"
                      id="registeredOfficeAddressCountry"
                      name="registeredOfficeAddressCountry"
                      value={introductionData.registeredOfficeAddressCountry}
                      onChange={handleIntroductionInputChange}
                      maxLength="10"
                  />*/}
                    <div className='custom-select-wrapper'>
                      {isEditMode ? (<select id="countryOfTaxResidency" onChange={handleSelectCountryOfTaxResidencyChange} value={selectedAPICountryOfTaxResidencyValue}
                        className='form-control select-input-registration'>
                        <option value="">Select an option</option>
                        {[...distinctCountryMap].map(([key, value], index) => (
                          <option key={index} value={value}>
                            {key}
                          </option>
                        ))}
                      </select>

                      ) : (
                        <span className='view-mode-stylings'> {kyvData.countryOfTaxResidencyLabel} </span>
                      )
                      }
                      {
                        isEditMode ? (

                          <i className="fas fa-circle-chevron-down custom-caret"></i>
                        ) : (
                          <span></span>
                        )
                      }
                    </div>
                  </div>
                </div>

                {/* Date of Incorporation/Birth */}
                <div className="row mt-3">
                  {/* Date of Incorporation/Birth Label */}
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="dateOfIncoperation">
                      Date of Incorporation/Birth
                    </label>
                  </div>
                  {/* Date of Incorporation/Birth Label */}
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="date"
                      className="form-control select-input-registration"
                      id="dateOfIncorporation"
                      name="dateOfIncorporation"
                      value={kyvData.dateOfIncorporation}
                      onChange={handleKYVInputChange}
                      max={getCurrentDate()}
                    />) : (
                      <span className='view-mode-stylings'>{formatDateForDisplay(kyvData.dateOfIncorporation)}</span>
                    )}
                  </div>
                </div>

                {/* PAN Number */}
                <div className='row mt-3'>
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="PANNumber">
                      PAN Number
                    </label>
                  </div>
                  <div className='col-7 col-md-4 text-left-registration'>
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="PANNumber"
                      name="PANNumber"
                      placeholder="Enter your PAN Number"
                      value={kyvData.PANNumber}
                      onChange={handleKYVInputChange}
                      minLength="10"
                      maxLength="10"
                    />) : (
                      <span className='view-mode-stylings'>{kyvData.PANNumber}</span>
                    )}
                    {errorMessages.PANError && <div className="error-message">{errorMessages.PANError}</div>}
                  </div>
                </div>

                {/* Category/Type Of Work */}
                <div className='row mt-3'>
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label htmlFor="typeOfWork">
                      Category/ Type of Work
                    </label>
                  </div>
                  <div className='col-7 col-md-4 custom-select-container text-left-registration'>
                    <div className='custom-select-wrapper'>
                      {isEditMode ? (<select id="typeOfWork" name="typeOfWork" className="form-control select-input-registration"
                        onChange={handleKYVInputChange} value={kyvData.typeOfWork}
                      >
                        <option value="">Select Option</option>
                        <option value="Information Technology">Information Technology</option>
                        <option value="urd">URD</option>
                        <option value="wc">WC</option>
                        <option value="endCustomer">End Customer</option>
                        <option value="govt">Govt</option>
                      </select>) : (
                        <span className='view-mode-stylings'>{kyvData.typeOfWork}</span>
                      )}
                      {
                        isEditMode ? (
                          <i className="fas fa-circle-chevron-down custom-caret"></i>
                        ) : (
                          <span></span>
                        )
                      }
                    </div>
                  </div>
                </div>

                {/* CIN Number */}
                <div className="d-flex flex-column mt-3">
                  <div className="row d-flex">
                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="CINNumber">
                        CIN Number
                      </label>
                    </div>
                    <div className="col-7 col-md-4 text-left-registration">
                      {isEditMode ? (<input
                        type="text"
                        className=" form-check form-control select-input-registration"
                        id="CINNumber"
                        name="CINNumber"
                        placeholder="Enter CIN Number"
                        minLength={21}
                        maxLength={21}
                        value={kyvData.CINNumber}
                        onChange={handleKYVInputChange}
                        disabled={kyvData.isCINNotRegisteredChecked || kyvData.isCINNotApplicableChecked}
                      />) : (
                        kyvData.CINStatus.toUpperCase() === "FILLED" ? (
                          <span className='view-mode-stylings'>{kyvData.CINNumber}</span>
                        ) : (
                          <span className='view-mode-stylings'>{kyvData.CINStatus}</span>
                        )
                      )
                      }
                      {errorMessages.CINError && <div className="error-message">{errorMessages.CINError}</div>}
                    </div>
                  </div>
                  {isEditMode ? (<div className='row d-flex'>
                    <div className="col-5 col-md-2 form-check"></div>
                    <div className="form-check col-4 col-md-2">
                      <label className="form-check-label check-box-styling" htmlFor="CINNotRegisteredChecked">
                        Not Registered
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="CINNotRegisteredChecked"
                          name="isCINNotRegisteredChecked"
                          checked={kyvData.isCINNotRegisteredChecked}
                          onChange={handleKYVInputChange}
                        />
                      </label>
                    </div>
                    <div className="form-check col-3 col-md-2">
                      <label className="form-check-label check-box-styling" htmlFor="CINNotApplicableChecked">
                        Not Applicable
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="CINNotApplicableChecked"
                          name="isCINNotApplicableChecked"
                          checked={kyvData.isCINNotApplicableChecked}
                          onChange={handleKYVInputChange}
                        />
                      </label>
                    </div>
                  </div>) : (
                    <span></span>
                  )}
                </div>

                {/* GST Number */}
                <div className="d-flex flex-column mt-3">
                  <div className="row d-flex">
                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="GSTNumber">
                        GST Number
                      </label>
                    </div>
                    <div className="col-7 col-md-4 text-left-registration">
                      {isEditMode ? (<input
                        type="text"
                        className="form-check form-control select-input-registration"
                        id="GSTNumber"
                        name="GSTNumber"
                        placeholder="Enter GST Number"
                        value={kyvData.GSTNumber}
                        onChange={handleKYVInputChange}
                        minLength={15}
                        maxLength={15}
                        disabled={kyvData.isGSTNotRegisteredChecked || kyvData.isGSTNotApplicableChecked}
                      />) :
                        (
                          kyvData.GSTStatus.toUpperCase() === "FILLED" ? (
                            <span className='view-mode-stylings'>{kyvData.GSTNumber}</span>
                          ) : (
                            <span className='view-mode-stylings'>{kyvData.GSTStatus}</span>
                          )
                        )}
                      {errorMessages.GSTError && <div className="error-message">{errorMessages.GSTError}</div>}
                    </div>
                  </div>
                  {isEditMode ? (<div className='row d-flex'>
                    <div className="col-5 col-md-2"></div>
                    <div className="form-check col-4 col-md-2">
                      <label className="form-check-label check-box-styling" htmlFor="GSTNotRegisteredChecked">
                        Not Registered
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="GSTNotRegisteredChecked"
                          name="isGSTNotRegisteredChecked"
                          checked={kyvData.isGSTNotRegisteredChecked}
                          onChange={handleKYVInputChange}
                        />
                      </label>
                    </div>
                    <div className="form-check col-3 col-md-2">
                      <label className="form-check-label check-box-styling" htmlFor="GSTNotApplicableChecked">
                        Not Applicable
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="GSTNotApplicableChecked"
                          name="isGSTNotApplicableChecked"
                          checked={kyvData.isGSTNotApplicableChecked}
                          onChange={handleKYVInputChange}
                        />
                      </label>
                    </div>
                  </div>) : (
                    <span></span>)}

                </div>

                {/* UDYAM or MSME  */}
                <div className="row d-flex mt-3">
                  <label className="col-5 col-md-2 form-check-label form-label-styling-registration">UDYAM or MSME</label>
                  {isEditMode ? (
                    <>
                      {/* UDYAM Radio Button */}
                      <div className="col-3 col-md-2 form-check">
                        <label className="form-label-styling-registration">
                          <input
                            type="radio"
                            name="radioOption"
                            value="UDYAM"
                            checked={kyvData.radioOption === 'UDYAM'}
                            onChange={handleKYVInputChange}
                          />
                          UDYAM
                        </label>
                      </div>

                      {/* MSME Radio Button */}
                      <div className="col-3 col-md-2">
                        <label className="form-label-styling-registration">
                          <input
                            type="radio"
                            name="radioOption"
                            value="MSME"
                            checked={kyvData.radioOption === 'MSME'}
                            onChange={handleKYVInputChange}
                          />
                          MSME
                        </label>
                      </div>
                    </>
                  ) : (
                    // Display selected option when not in edit mode
                    <div className="col-3 col-md-2 text-left-registration">
                      <span className='view-mode-stylings'>{kyvData.radioOption}</span>
                    </div>
                  )}
                </div>

                {/* UDYAM or MSME associated Options Rendering */}
                <div className='row mt-3'>
                  {renderUdyamOrMSME()}
                </div>

                {/* EPF Number */}
                <div className="d-flex flex-column mt-3">
                  <div className="row d-flex">
                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="EPFNumber">
                        EPF Number
                      </label>
                    </div>
                    <div className="col-7 col-md-4 text-left-registration">
                      {isEditMode ?
                        (
                          <>
                            <input
                              type="text"
                              className="form-check form-control select-input-registration"
                              id="EPFNumber"
                              name="EPFNumber"
                              placeholder="Enter EPF Number"
                              value={kyvData.EPFNumber}
                              onChange={handleKYVInputChange}
                              minLength={22}
                              maxLength={22}
                              disabled={kyvData.isEPFNotRegisteredChecked || kyvData.isEPFNotApplicableChecked}
                            />
                            {errorMessages.EPFError && <div className="error-message">{errorMessages.EPFError}</div>}
                          </>
                        ) : (
                          kyvData.EPFStatus.toUpperCase() === "FILLED" ? (
                            <span className='view-mode-stylings'>{kyvData.EPFNumber}</span>
                          ) : (
                            <span className='view-mode-stylings'>{kyvData.EPFStatus}</span>
                          )
                        )
                      }
                    </div>
                  </div>
                  {isEditMode ? (<div className='row d-flex'>
                    <div className="col-5 col-md-2"></div>
                    <div className="form-check col-4 col-md-2">
                      <label className="form-check-label check-box-styling" htmlFor="EPFNotRegisteredChecked">
                        Not Registered
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="EPFNotRegisteredChecked"
                          name="isEPFNotRegisteredChecked"
                          checked={kyvData.isEPFNotRegisteredChecked}
                          onChange={handleKYVInputChange}
                        />
                      </label>
                    </div>
                    <div className="form-check col-3 col-md-2">
                      <label className="form-check-label check-box-styling" htmlFor="EPFNotApplicableChecked">
                        Not Applicable
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="EPFNotApplicableChecked"
                          name="isEPFNotApplicableChecked"
                          checked={kyvData.isEPFNotApplicableChecked}
                          onChange={handleKYVInputChange}
                        />
                      </label>
                    </div>
                  </div>
                  ) : (
                    <span></span>
                  )}

                </div>

                {/* ESIC Number */}

                <div className="d-flex flex-column mt-3">
                  <div className="row d-flex">
                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="ESICNumber">
                        ESIC Number
                      </label>
                    </div>
                    <div className='col-7 col-md-4 text-left-registration'>
                      {isEditMode ? (
                        <>
                          <div className="d-flex flex-column form-check">
                            <div>
                              <input
                                type="tel"
                                className="form-control select-input-registration"
                                id="ESICNumber"
                                name="ESICNumber"
                                placeholder="Enter ESIC Number"
                                value={kyvData.ESICNumber}
                                onChange={handleKYVInputChange}
                                minLength={17}
                                maxLength={17}
                                disabled={kyvData.isESICNotApplicableChecked || kyvData.isESICNotRegisteredChecked}
                              />
                              {errorMessages.ESICError && <div className="error-message">{errorMessages.ESICError}</div>}
                            </div>
                          </div>
                        </>) : (
                        kyvData.ESICStatus.toUpperCase() === "FILLED" ? (
                          <span className='view-mode-stylings'>{kyvData.ESICNumber}</span>
                        ) : (
                          <span className='view-mode-stylings'>{kyvData.ESICStatus}</span>
                        )
                      )
                      }

                      <div className="row">
                        <div className="col-md-6">
                          {isEditMode ? (
                            <div className="form-check text-center">
                              <label className="form-check-label check-box-styling" htmlFor="ESICNotRegisteredChecked">
                                Not Registered
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="ESICNotRegisteredChecked"
                                  name="isESICNotRegisteredChecked"
                                  checked={kyvData.isESICNotRegisteredChecked}
                                  onChange={handleKYVInputChange}
                                />
                              </label>
                            </div>
                          ) : (
                            <span></span>
                          )}
                        </div>

                        <div className="col-md-6">
                          {isEditMode ? (
                            <div className="form-check text-center">
                              <label className="form-check-label check-box-styling" htmlFor="ESICNotApplicableChecked">
                                Not Applicable
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="ESICNotApplicableChecked"
                                  name="isESICNotApplicableChecked"
                                  checked={kyvData.isESICNotApplicableChecked}
                                  onChange={handleKYVInputChange}
                                />
                              </label>
                            </div>
                          ) : (
                            <span></span>
                          )}
                        </div>
                      </div>


                    </div>


                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="ESICRelatedDistrict">
                        District
                      </label>
                    </div>

                    {/*<PhoneInput
                      type="number"
                      className="phone-input variation-height"
                      id="TANMobileNumber"
                      name="TANMobileNumber"
                      placeholder="Enter Mobile Number"
                      //country="in"
                      value={kyvData.TANMobileNumber}
                      onChange={handleKYVPhoneInputChange}
                      maxLength="10"
                      disabled={kyvData.isTANNotApplicableChecked}
                    />)*/}

                    <div className="col-7 col-md-4 form-check text-left-registration">
                      {isEditMode ? (
                        <input
                          type="text"
                          id="ESICRelatedDistrict"
                          className='form-control select-input-registration'
                          name="ESICRelatedDistrict"
                          value={kyvData.ESICRelatedDistrict}
                          onChange={handleKYVInputChange}
                          placeholder="ESIC Related District"
                          maxLength={80}
                          disabled={kyvData.isESICNotApplicableChecked || kyvData.isESICNotRegisteredChecked}
                        />
                      ) : (
                        kyvData.ESICStatus.toUpperCase() === "FILLED" ? (
                          <span className='view-mode-stylings'>{kyvData.ESICRelatedDistrict}</span>
                        ) : (
                          <span className='view-mode-stylings'>{kyvData.ESICStatus}</span>
                        )
                      )
                      }
                    </div>

                  </div>
                  <div className='d-flex'>
                    <div className="col-5 col-md-2"></div>

                  </div>

                </div>

                {/*
              <div className="d-flex flex-column mt-3">
                <div className="row d-flex">
                  <div className="col-5 col-md-2 form-label-styling-registration">
                    <label className="form-check-label" htmlFor="ESICNumber">
                      ESIC Number
                    </label>
                  </div>
                  <div className='d-flex col-7 col-md-4 text-left-registration'>
                    <div className="col-8 col-md-8 d-flex flex-column">
                      <div className='form-check'>
                        {isEditMode ? (
                          <>
                            <input
                              type="number"
                              className="form-control select-input-registration"
                              id="ESICNumber"
                              name="ESICNumber"
                              placeholder="Enter ESIC Number"
                              value={kyvData.ESICNumber}
                              pattern="\d{17}"
                              onChange={handleKYVInputChange}
                              disabled={kyvData.isESICNotRegisteredChecked || kyvData.isESICNotApplicableChecked}
                            />
                            {errorMessages.ESICError && <div className="error-message">{errorMessages.ESICError}</div>}
                          </>) : (
                          <span className='view-mode-stylings'>{kyvData.ESICNumber}</span>
                        )}
                      </div>
                      {isEditMode ? (
                        <>
                          <div>
                            <label className="form-check-label check-box-styling" htmlFor="ESICNotRegisteredChecked">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="ESICNotRegisteredChecked"
                                name="isESICNotRegisteredChecked"
                                checked={kyvData.isESICNotRegisteredChecked}
                                onChange={handleKYVInputChange}
                              />
                              Not Registered
                            </label>
                          </div>

                          <div>
                            <label className="form-check-label check-box-styling" htmlFor="ESICNotApplicableChecked">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="ESICNotApplicableChecked"
                                name="isESICNotApplicableChecked"
                                checked={kyvData.isESICNotApplicableChecked}
                                onChange={handleKYVInputChange}
                              />
                              Not Applicable
                            </label>

                          </div>
                        </>) : (
                        <span></span>
                      )}
                    </div>

                    <div className='col-6 col-md-6 d-flex flex-column form-check'>
                      <div className='d-flex'>
                        <div className='col-2 col-md-2'></div>
                        <div className='col-10 col-md-6 text-left-registration'>
                          <label htmlFor="ESICRelatedDistrict" className="form-label-styling-registration">
                            District
                          </label>
                        </div>
                      </div>
                      {isEditMode ? (<div className='d-flex'>
                        <div className='col-2 col-md-2'></div>
                        <div className='col-10 col-md-6 custom-select-container'>
                          <div className='custom-select-wrapper'>
                            <select id="ESICRelatedDistrict" name="ESICRelatedDistrict" className="form-control select-input-registration text-center"
                              onChange={handleKYVInputChange} value={kyvData.ESICRelatedDistrict}
                              disabled={kyvData.isESICNotRegisteredChecked || kyvData.isESICNotApplicableChecked}
                            >
                              <option value="">Select District</option>
                              <option value="Hyderabad">Hyderabad</option>
                              <option value="Karimnagar">Karimnagar</option>
                              <option value="Warangal">Warangal</option>
                            </select>
                            <i className="fas fa-circle-chevron-down custom-caret"></i>
                          </div>
                        </div>
                      </div>) : (
                        <span className='view-mode-stylings'>{kyvData.ESICRelatedDistrict}</span>
                      )}
                    </div>
                  </div>

                </div>
              </div>*/}

                {/* TAN Number */}
                <div className="d-flex flex-column mt-3">
                  <div className="row d-flex">
                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="TANNumber">
                        TAN Number
                      </label>
                    </div>
                    <div className='col-7 col-md-4 text-left-registration'>
                      {isEditMode ? (
                        <>
                          <div className="d-flex flex-column form-check">
                            <div>
                              <input
                                type="text"
                                className="form-control select-input-registration"
                                id="TANNumber"
                                name="TANNumber"
                                placeholder="Enter TAN Number"
                                value={kyvData.TANNumber}
                                onChange={handleKYVInputChange}
                                minLength={10}
                                maxLength={10}
                                disabled={kyvData.isTANNotApplicableChecked}
                              />
                              {errorMessages.TANError && <div className="error-message">{errorMessages.TANError}</div>}
                            </div>
                          </div>
                        </>) : (
                        kyvData.TANStatus === "Filled" ? (
                          <span className='view-mode-stylings'>{kyvData.TANNumber}</span>
                        ) : (
                          <span className='view-mode-stylings'>{kyvData.TANStatus}</span>
                        )
                      )}

                      {isEditMode ? (<div className="form-check text-center">
                        <label className="form-check-label check-box-styling" htmlFor="TANNotApplicableChecked">
                          Not Applicable
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="TANNotApplicableChecked"
                            name="isTANNotApplicableChecked"
                            checked={kyvData.isTANNotApplicableChecked}
                            onChange={handleKYVInputChange}
                          />
                        </label>
                      </div>
                      ) : (
                        <span></span>
                      )}

                    </div>


                    <div className="col-5 col-md-2 form-label-styling-registration">
                      <label className="form-check-label" htmlFor="TANMobileNumber">
                        Mobile Number
                      </label>
                    </div>

                    {/*<PhoneInput
                      type="number"
                      className="phone-input variation-height"
                      id="TANMobileNumber"
                      name="TANMobileNumber"
                      placeholder="Enter Mobile Number"
                      //country="in"
                      value={kyvData.TANMobileNumber}
                      onChange={handleKYVPhoneInputChange}
                      maxLength="10"
                      disabled={kyvData.isTANNotApplicableChecked}
                    />)*/}

                    <div className="col-7 col-md-4 form-check text-left-registration">
                      {isEditMode ? (
                        <input
                          type="tel"
                          id="TANMobileNumber"
                          className='form-control select-input-registration'
                          name="TANMobileNumber"
                          value={kyvData.TANMobileNumber}
                          onChange={handlePhoneInputChange}
                          placeholder="TAN Associated Mobile Number"
                          pattern="[0-9]*"
                          minLength={10}
                          maxLength={10}
                          disabled={kyvData.isTANNotApplicableChecked}
                        />
                      ) : (
                        kyvData.isTANNotApplicableChecked ? (
                          <span className='view-mode-stylings'>{kyvData.TANNumber}</span>
                        ) : <span className='view-mode-stylings'>NA</span>
                      )}
                    </div>

                  </div>
                  <div className='d-flex'>
                    <div className="col-5 col-md-2"></div>

                  </div>

                </div>
              </div>

            )}

            {currentStep === 3 && (
              <div className="table1-registration">

                <div className='row margin-settings'>

                  {/* Swift/ IFSC Code */}
                  <div className="col-5 col-md-2 text-left">
                    <label htmlFor="swiftCode" className="form-label-styling-registration">
                      IFSC Code
                    </label>
                  </div>
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="swiftCode"
                      name="swiftCode"
                      placeholder="Enter Swift/IFSC Code"
                      value={bankAccountData.swiftCode}
                      onChange={handleBankAccountInputChange}
                      minLength="8"
                      maxLength="11"
                    />) : (
                      <span className='view-mode-stylings'>{bankAccountData.swiftCode}</span>
                    )}
                  </div>

                  {/* Bank Account Holder Name */}
                  <div className="col-5 col-md-2 text-left">
                    <label htmlFor="bankAccountHolderName" className="form-label-styling-registration">
                      Account Holder Name
                    </label>
                  </div>
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankAccountHolderName"
                      name="bankAccountHolderName"
                      placeholder="Enter Account Holder Name"
                      value={bankAccountData.bankAccountHolderName}
                      onChange={handleBankAccountInputChange}
                      maxLength="60"
                    />)
                      : (
                        <span className='view-mode-stylings'>{bankAccountData.bankAccountHolderName}</span>
                      )}
                  </div>

                </div>

                <div className='row margin-settings'>

                  {/* Vendor's Bank Account Number */}
                  <div className="col-5 col-md-2 text-left">
                    <label htmlFor="bankAccountNumber" className="form-label-styling-registration">
                      Account Number
                    </label>
                  </div>
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="number"
                      className="form-control select-input-registration"
                      id="bankAccountNumber"
                      name="bankAccountNumber"
                      placeholder="Enter Account Number"
                      value={bankAccountData.bankAccountNumber}
                      onChange={handleBankAccountInputChange}
                      minLength={12}
                      maxLength={18}
                    />) : (
                      <span className='view-mode-stylings'>{bankAccountData.bankAccountNumber}</span>
                    )}
                    {bankAccountNumberError && <span style={{ color: 'red', fontSize: '10px' }}>{bankAccountNumberError}</span>}
                  </div>

                  {/* Bank Name */}
                  <div className="col-5 col-md-2 text-left">
                    <label htmlFor="bankName" className="form-label-styling-registration">
                      Bank Name
                    </label>
                  </div>
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankName"
                      name="bankName"
                      value={bankAccountData.bankName}
                      onChange={handleBankAccountInputChange}
                      maxLength="60"
                    />) : (
                      <span className='view-mode-stylings'>{bankAccountData.bankName}</span>
                    )}
                  </div>


                </div>

                <div className='row margin-settings'>

                  {/* Bank Branch */}
                  <div className="col-5 col-md-2 text-left">
                    <label htmlFor="branchName" className="form-label-styling-registration">
                      Bank Branch
                    </label>
                  </div>
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="branchName"
                      name="branchName"
                      value={bankAccountData.branchName}
                      onChange={handleBankAccountInputChange}
                      maxLength="60"
                    />) : (
                      <span className='view-mode-stylings'>{bankAccountData.branchName}</span>
                    )}
                  </div>

                  {/* Bank Address Region */}
                  <div className="col-5 col-md-2 text-left">
                    <label htmlFor="bankAddressRegion" className="form-label-styling-registration">
                      Region
                    </label>
                  </div>
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankAddressRegion"
                      name="bankAddressRegion"
                      value={bankAccountData.bankAddressRegion}
                      onChange={handleBankAccountInputChange}
                      maxLength="150"
                    />)
                      : (
                        <span className='view-mode-stylings'>{bankAccountData.bankAddressRegion}</span>
                      )}
                  </div>
                </div>

                <div className='row margin-settings'>
                  {/* Bank Street */}
                  <div className="col-5 col-md-2 text-left">
                    <label htmlFor="bankAddressStreet" className="form-label-styling-registration">
                      Street
                    </label>
                  </div>
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankAddressStreet"
                      name="bankAddressStreet"
                      value={bankAccountData.bankAddressStreet}
                      onChange={handleBankAccountInputChange}
                      maxLength="150"
                    />)
                      : (
                        <span className='view-mode-stylings'>{bankAccountData.bankAddressStreet}</span>
                      )}
                  </div>

                  {/* Bank City */}
                  <div className="col-5 col-md-2 text-left">
                    <label htmlFor="bankAddress" className="form-label-styling-registration">
                      City
                    </label>
                  </div>
                  <div className="col-7 col-md-4 text-left-registration">
                    {isEditMode ? (<input
                      type="text"
                      className="form-control select-input-registration"
                      id="bankAddressCity"
                      name="bankAddressCity"
                      value={bankAccountData.bankAddressCity}
                      onChange={handleBankAccountInputChange}
                      maxLength="150"
                    />)
                      : (
                        <span className='view-mode-stylings'>{bankAccountData.bankAddressCity}</span>
                      )}
                  </div>
                </div>



                {/* iBAN 
                <div className="col-12 col-md-4">
                  <div className="form-group text-left-registration">
                    <label htmlFor="iBAN" className="label-styling-registration">
                      IBAN
                    </label>
                    <input
                      type="text"
                      className="form-control select-input-registration"
                      id="iBAN"
                      name="iBAN"
                      placeholder="Enter IBAN"
                      value={bankAccountData.iBAN}
                      onChange={handleBankAccountInputChange}
                      maxLength="10"
                    />
                  </div>
                </div>*/}

              </div>
            )}

            {currentStep === 4 && (
              <div className="table1-registration">

                {/* Note Information */}
                <div className="row margin-settings">
                  <div className="col-12 document-note-bg">
                    <p className='document-note-settings'>Note: Only Image or PDF Documents are accepted. Maximun file size per Document is <b style={{ color: "red" }}>5MB</b></p>
                  </div>
                </div>

                <FileUploadForm />

              </div>
            )}

            <div className="text-center mb-4">
              {currentStep === 1 && (
                <div>
                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={handleReset}>
                    Reset
                  </button>) : (
                    <span></span>
                  )}

                  <button type="button" className="btn btn-primary button-registration" onClick={handleNext} disabled={!isIntroductionData}>
                    Next
                  </button>

                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={saveIntroductionDataToDatabase}>
                    Save
                  </button>) : (
                    <span></span>
                  )}

                  <div>
                    {isIntroductionDataResetted && <span className='data-resetted-success'>Data Resetted successfully!</span>}
                  </div>

                  <div>
                    {isIntroductionDataSaved && <span className='data-saved-success'>Data saved successfully!</span>}
                  </div>

                </div>
              )}

              {(currentStep === 2) && (
                <>
                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={handleReset}>
                    Reset
                  </button>) : (
                    <span></span>
                  )}

                  <button type="button" className="btn btn-primary button-registration" onClick={handleBack}>
                    Back
                  </button>

                  <button type="button" className="btn btn-primary button-registration" onClick={handleNext} disabled={!isKYVData}>
                    Next
                  </button>

                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={saveKYVDataToDatabase}>
                    Save
                  </button>
                  ) : (
                    <span></span>
                  )}

                  <div>
                    {isKYVDataResetted && <span className='data-resetted-success'>Data Resetted successfully!</span>}
                  </div>

                  <div>
                    {isKYVDataSaved && <span className='data-saved-success'>Data saved successfully!</span>}
                  </div>

                </>
              )}

              {(currentStep === 3) && (
                <>
                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={handleReset}>
                    Reset
                  </button>) : (
                    <span></span>
                  )}

                  <button type="button" className="btn btn-primary button-registration" onClick={handleBack}>
                    Back
                  </button>

                  <button type="button" className="btn btn-primary button-registration" onClick={handleNext} disabled={!isBankData}>
                    Next
                  </button>

                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={saveBankInformationDataToDatabase}>
                    Save
                  </button>) : (
                    <span></span>
                  )}

                  <div>
                    {isBankDataResetted && <span className='data-resetted-success'>Data Resetted successfully!</span>}
                  </div>

                  <div>
                    {isBankDataSaved && <span className='data-saved-success'>Data saved successfully!</span>}
                  </div>
                </>
              )}

              {currentStep === 4 && (
                <>
                  <button type="button" className="btn btn-primary button-registration" onClick={handleBack}>
                    Back
                  </button>

                  {isEditMode ? (<button type="button" className="btn btn-primary button-registration" onClick={handleNext}>
                    Save
                  </button>) : (
                    <span></span>
                  )}

                  {isEditMode ? (<button type="submit" className="btn btn-primary button-registration" onClick={handleSubmission} disabled={!isEditMode}>
                    Submit
                  </button>) : (
                    <span></span>
                  )}

                </>
              )}

            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Submit;